<template>
  <vm-map-element :vm-map="map" :class="[plainBaseColor ? 'plain-color-' + plainBaseColor : '']">
    <keep-alive>
      <component
        :is="viewerMode?.component || 'div'"
        :mode-key="mapMode"
        :register-mode="registerViewerMode"
        v-bind="viewerMode?.data ?? {}"
      />
    </keep-alive>
  </vm-map-element>
</template>

<script setup lang="ts">
import { computed, reactive, markRaw, watchEffect, watch, toRaw, onBeforeMount, defineAsyncComponent } from 'vue';
import { VmMapElement } from 'vuemap';
import { useMainMap } from './map';
import { setStyleConfig } from './mapStyling/styleService';
import { useOsm, useOsmLight, useOsmWorld } from './baseLayers';
import { useStore } from 'vuex';
import { debounce } from 'lodash-es';
import { ENABLED_DATA_MODES } from '@composables/useDataModes';
import useJsdi from '@composables/useJsdi';
import {
  MP_LIVE_VIEWER,
  MP_HISTORICAL_VIEWER,
  MP_MODEL_STA_VIEWER,
  MP_MODEL_DTA_VIEWER,
  MP_MODEL_STA_COMPARISON,
  MP_MODEL_DTA_COMPARISON,
  MP_HISTORICAL_COMPARISON,
  MP_COMBINED_COMPARISON,
  MP_H_AGGREGATION_COMPARISON,
  getModelTypeByModelName,
  MP_HISTORICAL_AGGREGATION,
  MP_MODEL_STA_AGGREGATION,
  MP_MODEL_STA_AGGREGATION_COMPARISON,
  MP_MODEL_STA_DATASET,
  MP_DATASET_MATRIX_COMPARISON,
  MP_MATRICES_COMPARISON,
} from '@keys/index';
import type { MapBase, Center, Zoom, MapStyle } from '@store/modules/map';
import type { Ref } from 'vue';

type ViewerComponentData = {
  date?: Ref<TmCalendarDate>;
  dates?: Ref<TmCalendarDate>;
  scenarios?: Ref<TmComparisonItems>;
  datasetMatrix?: Ref<TmComparisonItems>;
  matrices?: Ref<TmComparisonItems>;
  modelType?: Ref<TmModelType>;
  mapStyle?: Ref<MapStyle>;
  datasetId?: Ref<number>;
};
type ViewerMode = {
  component: string;
  data?: ViewerComponentData;
};

const store = useStore();
const map = useMainMap();
const { updateJsdiData } = useJsdi();

const mapMode = computed<TmMapMode>(() => store.state.map.mapMode);
const viewerModes = reactive<Map<string, ViewerMode>>(new Map());
const calendarDate = computed<TmCalendarDate>(() => store.getters['map/getCalendarDate']());
const modelType = computed<TmModelType>(() => store.state.scenarios.model.type);
const mapStyle = computed<MapStyle>(() => store.state.map.mapStyle);
const mapBaseId = computed<MapBase>(() => store.state.map.base);
const plainBaseColor = computed<string>(() => mapBaseId.value?.split('plain')?.[1]?.toLocaleLowerCase());
const viewerMode = computed<ViewerMode | undefined>(() => viewerModes.get(mapMode.value));
const mapPosition = computed<{ zoom: Zoom; center: Center }>(() => ({
  zoom: store.state.map.zoom,
  center: store.state.map.center,
}));

const addViewerMode = ({ key, component, data = {} }: ViewerMode & { key: string }) => {
  if (viewerModes.has(key)) return;
  viewerModes.set(key, {
    component: markRaw(defineAsyncComponent(() => import(`./mapModes/${component}.vue`))),
    data,
  });
};

const setupBaseMaps = () => {
  const enabledBaseMaps = import.meta.env.VITE_ENABLED_BASE_MAPS?.split(', ') || [];
  if (enabledBaseMaps.includes('osm')) map.manager.addBaseMap(useOsm);
  if (enabledBaseMaps.includes('osmLight')) map.manager.addBaseMap(useOsmLight);
  if (enabledBaseMaps.includes('osmWorld')) map.manager.addBaseMap(useOsmWorld);
};

const setupViewerModes = () => {
  const isEnabledDataMode = (dataModeKey: TmDataMode) => ENABLED_DATA_MODES.includes(dataModeKey);
  const isEnabledModelType = (modelType: TmModelType) => {
    // this is only usable because the app is refreshed when the model is switched - therefore we only have one model type enabled at a time
    // TODO: if the model switching is ever made 'reactive' -> this would need to change and we would need the full list of model types fetched prior to this check
    const activeModelType = getModelTypeByModelName(store.state.scenarios.model.name);
    return modelType === activeModelType;
  };
  const isModelCalibrationEnabled = () => !!import.meta.env.VITE_ENABLED_CALIBRATION;
  const isCombinedComparisonEnabled = () => !import.meta.env.VITE_DISABLED_COMBINED_COMPARISON;

  if (isEnabledDataMode('live'))
    addViewerMode({
      key: MP_LIVE_VIEWER,
      component: 'ModeLiveViewer',
      data: { mapStyle },
    });

  if (isEnabledDataMode('historical')) {
    addViewerMode({
      key: MP_HISTORICAL_VIEWER,
      component: 'ModeHistoricalViewer',
      data: { date: calendarDate },
    });
    addViewerMode({
      key: MP_HISTORICAL_AGGREGATION,
      component: 'ModeHistoricalAggregation',
      data: { dates: calendarDate },
    });
  }

  if (isEnabledDataMode('model') && isEnabledModelType('DTA'))
    addViewerMode({
      key: MP_MODEL_DTA_VIEWER,
      component: 'ModeModelDTAViewer',
      data: { date: calendarDate, mapStyle },
    });

  if (isEnabledDataMode('model') && isEnabledModelType('STA')) {
    addViewerMode({
      key: MP_MODEL_STA_VIEWER,
      component: 'ModeModelSTAViewer',
      data: { date: calendarDate, mapStyle },
    });
    addViewerMode({
      key: MP_MODEL_STA_AGGREGATION,
      component: 'ModeModelSTAAggregation',
      data: { dates: calendarDate, mapStyle },
    });
    addViewerMode({
      key: MP_MODEL_STA_DATASET,
      component: 'ModeModelSTADataset',
      data: { datasetId: computed(() => store.getters['map/getPreviewedDatasetId']) },
    });
  }

  if (isEnabledDataMode('comparison') && isEnabledDataMode('model') && isEnabledModelType('DTA'))
    addViewerMode({
      key: MP_MODEL_DTA_COMPARISON,
      component: 'ModeModelDTAComparison',
      data: {
        dates: calendarDate,
        scenarios: computed(() => store.getters['map/getComparedItems']),
        modelType,
      },
    });

  if (isEnabledDataMode('comparison') && isEnabledDataMode('model') && isEnabledModelType('STA')) {
    addViewerMode({
      key: MP_MODEL_STA_COMPARISON,
      component: 'ModeModelSTAComparison',
      data: {
        dates: calendarDate,
        scenarios: computed(() => store.getters['map/getComparedItems']),
      },
    });
    addViewerMode({
      key: MP_MODEL_STA_AGGREGATION_COMPARISON,
      component: 'ModeModelSTAAggregationComparison',
      data: {
        dates: calendarDate,
        scenarios: computed(() => store.getters['map/getComparedItems']),
      },
    });
  }

  if (
    isModelCalibrationEnabled() &&
    isEnabledDataMode('comparison') &&
    isEnabledDataMode('model') &&
    isEnabledModelType('STA')
  ) {
    addViewerMode({
      key: MP_DATASET_MATRIX_COMPARISON,
      component: 'ModeDatasetMatrixComparison',
      data: { datasetMatrix: computed(() => store.getters['map/getComparedItems']) },
    });

    addViewerMode({
      key: MP_MATRICES_COMPARISON,
      component: 'ModeMatricesComparison',
      data: { matrices: computed(() => store.getters['map/getComparedItems']) },
    });
  }

  if (isEnabledDataMode('comparison') && isEnabledDataMode('historical')) {
    addViewerMode({
      key: MP_HISTORICAL_COMPARISON,
      component: 'ModeHistoricalComparison',
      data: { dates: calendarDate },
    });
    addViewerMode({
      key: MP_H_AGGREGATION_COMPARISON,
      component: 'ModeHistoricalAggregationComparison',
      data: { dates: calendarDate },
    });
  }

  if (
    isCombinedComparisonEnabled() &&
    isEnabledDataMode('comparison') &&
    isEnabledDataMode('historical') &&
    isEnabledDataMode('model') &&
    isEnabledModelType('STA')
  )
    addViewerMode({
      key: MP_COMBINED_COMPARISON,
      component: 'ModeCombinedComparison',
      data: {
        dates: calendarDate,
        scenarios: computed(() => store.getters['map/getComparedItems']),
        modelType,
      },
    });
};

const registerViewerMode = (key: string, layers: any) => map.manager.createMapState(key, layers); // TS_TODO: layers

const updateMapPosition = debounce(() => {
  const { center, zoom } = toRaw(map.view.state);
  store.dispatch('map/setPosition', { center, zoom });
}, 100);

const prepareJsdiData = () => {
  if (!ENABLED_DATA_MODES.includes('live')) return; // JSDI data are only needed for live data mode
  const isJsdiEnabled = !!import.meta.env.VITE_ENABLED_JSDI;
  if (!isJsdiEnabled) return;
  updateJsdiData();
};

// Sets currently selected mapMode as mapPreset (even if mode not found here, might come from somewhere else)
watchEffect(() => map.manager.setMapState(mapMode.value));

watch(mapBaseId, (id) => map.manager.setBaseMap(id.includes('plain') ? null : id), { immediate: true });

watch(map.view.state, updateMapPosition);

watch(mapPosition, (newPosition) => map.view.set(newPosition));

watch(
  mapStyle,
  () => {
    setStyleConfig(mapStyle.value);
    map.manager.forceRender();
  },
  { deep: true, immediate: true },
);

watch(
  () => map.manager.state.loading,
  (isLoading) => {
    // TODO: this is not reliable, sometimes causes unfinished loading bug...
    // store.dispatch(isLoading ? 'layout/startLoading' : 'layout/finishLoading');
    console.info('VUEMAP loading state ', isLoading ? 'started' : 'finished');
  },
);

onBeforeMount(() => {
  setupBaseMaps();
  setupViewerModes();
  prepareJsdiData();
});
</script>

<style>
/* Global styling - OL layer css filter */
.tm-layer-pastel {
  filter: grayscale(70%);
}
.plain-color-white {
  background-color: white;
}
.plain-color-grey {
  background-color: #e9ecef;
}

/* Global styling for OpenLayers base map attribution link */
.tm-ol-attribution {
  text-align: right;
  bottom: 0.5em;
  right: 0.5em;
  max-width: calc(100% - 1.3em);
  display: block;
  position: fixed;
}
.tm-ol-attribution a {
  color: #666666;
  text-decoration: none;
}
.tm-ol-attribution a:hover {
  color: #0094d2;
  text-decoration: underline;
}
.tm-ol-attribution ul {
  margin: 0;
  padding: 1px 0.5em;
  color: #333333;
  text-shadow: 0 0 2px white;
  font-size: 12px;
}
.tm-ol-attribution li {
  display: inline;
  list-style: none;
}
.tm-ol-attribution li:not(:last-child):after {
  content: ' ';
}
.tm-ol-attribution img {
  max-height: 2em;
  max-width: inherit;
  vertical-align: middle;
}
.tm-ol-attribution.ol-collapsed ul {
  display: none;
}
.tm-ol-attribution:not(.ol-collapsed) {
  background: rgba(255, 255, 255, 0.75);
}
.tm-ol-attribution.ol-uncollapsible {
  bottom: 0;
  right: 0;
  border-radius: 4px 0 0;
}
.tm-ol-attribution.ol-uncollapsible img {
  margin-top: -0.2em;
  max-height: 1.6em;
}
.tm-ol-attribution.ol-uncollapsible button {
  display: none;
}
</style>
