import { computed, ref } from 'vue';
import useMainApiRequest from '@composables/useMainApiRequest';
import { registerCacheInvalidation } from '@composables/useCache';
import useModels from '@composables/useModels';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { has, set, get, unset } from 'lodash-es';
import { isCachingStatus } from './useStatus';

export type TmMatrix = {
  id: number;
  name: string;
  description: string;
  createdAt: TmDate;
  isActive: boolean;
  isDefault: boolean;
  owner: string;
  matrixRefId: number | null;
  status: string;
};

const cachedMatrices = ref<Record<TmMatrix['id'], TmMatrix>>({});
const isOverviewCached = ref(false);

export default function useModelMatrices() {
  const router = useRouter();
  const store = useStore();
  const { t } = useI18n();
  const { makeRequest } = useMainApiRequest();
  const { fetchModels, refreshModel } = useModels();

  const trafficModelId = computed(() => store.state.scenarios.model.id);
  const matrices = computed(() => Object.values(cachedMatrices.value));

  const fetchMatrices = () => {
    if (isCacheAvailable() && !isMatrixRefreshing()) return;

    return makeRequest({
      url: `matrices/traffic-model/${trafficModelId.value}`,
      method: 'get',
      message: {
        error: {
          404: { summary: t('models.no matrices found'), severity: 'info' },
          default: t('models.error while fetching matrices'),
        },
      },
      onSuccess: ({ matrices }: { matrices: TmMatrix[] }) => {
        matrices.forEach((matrix) => set(cachedMatrices.value, matrix.id, matrix));
        isOverviewCached.value = true;
      },
    });
  };

  const fetchMatrix = (matrixId: TmMatrix['id']) => {
    if (isCacheAvailable(matrixId) && !isMatrixRefreshing(matrixId)) return;

    return makeRequest({
      url: `matrices/${matrixId}`,
      method: 'get',
      message: {
        error: t('models.error while fetching matrix'),
      },
      onSuccess: ({ matrix }: { matrix: TmMatrix }) => set(cachedMatrices.value, matrix.id, matrix),
    });
  };

  const createMatrix = (matrix: Partial<TmMatrix>) => {
    return makeRequest({
      url: `matrices`,
      method: 'post',
      data: {
        name: matrix.name,
        description: matrix.description,
        matrixRefId: matrix.matrixRefId,
        trafficModelId: trafficModelId.value,
      },
      message: {
        error: t('models.error while creating matrix'),
        success: t('models.matrix created successfully'),
      },
      onSuccess: (result: { matrix: TmMatrix }) => {
        set(cachedMatrices.value, result.matrix.id, result.matrix);
        router.push({ name: 'models.matrices' });
      },
    });
  };

  const updateMatrix = (matrixId: TmMatrix['id'], matrix: Partial<TmMatrix>, activateMatrix = false) => {
    return makeRequest({
      url: `matrices/${matrixId}`,
      method: 'patch',
      data: {
        name: matrix.name,
        description: matrix.description,
        // NOTE: only activation is possible (server validator refuses anything but true value)
        //       deactivation is automatic on the server when another matrix is activated
        //       also we should not send the 'isActive' prop in any other case, because server would than trigger the activation/deactivation process even for already active matrix..
        ...(activateMatrix && matrix.isActive ? { isActive: true } : {}),
      },
      message: {
        success: t('models.matrix updated successfully'),
        error: t('models.error while updating matrix'),
      },
      onSuccess: ({ matrix }: { matrix: TmMatrix }) => {
        set(cachedMatrices.value, matrix.id, matrix);

        if (activateMatrix) refreshModel(trafficModelId.value); // trigger model refresh to re-compute everything with the newly activated active matrix
        router.push({ name: 'models.matrices' });
      },
    });
  };

  /**
   * Calculate default traffic cache (base model) without scenario re-calculation.
   * For scenario re-calculation use the refreshModel function
   */
  const refreshMatrix = (id: TmMatrix['id']) => {
    return makeRequest({
      url: `matrices/refresh`,
      method: 'post',
      data: { id },
      message: {
        success: {
          severity: 'info',
          summary: t('models.matrix refreshing'),
        },
        error: t('models.error while refreshing matrix'),
      },
      onSuccess: ({ matrix }: { matrix: TmMatrix }) => set(cachedMatrices.value, matrix.id, matrix),
      onFailure: ({ data }) =>
        data?.matrix ? set(cachedMatrices.value, data.matrix.id, data.matrix) : invalidateMatrices(),
    });
  };

  const handleMatrixEnter = async () => {
    await fetchModels(); // ensure traffic models are already fetched
    store.dispatch('map/activateItem', {}); // deactivate any active scenario, si its mods are not displayed on map
  };

  const handleMatrixExit = () => {
    if (['models.matrices.edit', 'models.matrices'].includes(String(router.currentRoute.value.name))) return;
    // un-preview matrix to display original base model on the map
    store.dispatch('map/previewMatrix');
  };

  const getMatrixById = (id: TmMatrix['id']) => get(cachedMatrices.value, id);

  const isMatrixRefreshing = (matrixId?: TmMatrix['id']) => {
    if (!matrixId) return matrices.value.some((mx) => isCachingStatus(mx.status));
    const matrix = getMatrixById(matrixId);
    return isCachingStatus(matrix.status);
  };

  return {
    matrices,
    previewedMatrixId: computed<TmMatrix['id'] | undefined>(() => store.getters['map/getPreviewedMatrixId']),
    fetchMatrices,
    fetchMatrix,
    createMatrix,
    updateMatrix,
    handleMatrixEnter,
    handleMatrixExit,
    refreshMatrix,
    getMatrixById,
    previewMatrix: (id?: TmMatrix['id']) => store.dispatch('map/previewMatrix', id),
    updateCachedMatrix: (id: TmMatrix['id'], value: TmMatrix) => set(cachedMatrices.value, id, value),
    deleteCachedMatrix: (id: TmMatrix['id']) => unset(cachedMatrices.value, id),
    invalidateMatrices,
  };
}

const isCacheAvailable = (id?: TmMatrix['id']) => {
  if (id) return has(cachedMatrices.value, id);
  return isOverviewCached.value && !!Object.keys(cachedMatrices.value).length;
};

export const invalidateMatrices = () => {
  cachedMatrices.value = {};
  isOverviewCached.value = false;
};

registerCacheInvalidation('matrices', invalidateMatrices);
