/*
  App style configs constants
  Currently static (might become configurable later)
*/
import {
  MP_MODEL_STA_VIEWER,
  MP_MODEL_DTA_VIEWER,
  MP_MODEL_STA_COMPARISON,
  MP_MODEL_DTA_COMPARISON,
  MP_HISTORICAL_COMPARISON,
  MP_COMBINED_COMPARISON,
  MP_H_AGGREGATION_COMPARISON,
  MP_MODEL_STA_AGGREGATION,
  MP_MODEL_STA_AGGREGATION_COMPARISON,
  MP_MODEL_STA_DATASET,
  MP_DATASET_MATRIX_COMPARISON,
  MP_MATRICES_COMPARISON
} from '@keys/index';
import { formatRound, formatFixed, formatHour } from './utils/textFormat';

const customDiffColors = import.meta.env.VITE_PALETTE_DIFF_COLORS?.split(', ');
const customDiffLevels = import.meta.env.VITE_PALETTE_DIFF_LEVELS?.split(', ');

export const NO_MODE = 'noMode';

export const COLORS = {
  PRIMARY: '#0094d2',
  DISABLED: '#66bfe4',
  HIGHLIGHT: '#00FFFF', // used to be #007CAF (issue TraMod-1028)
  SELECTED: '#F8F004',
  SOURCE: '#3EC71D',
  TARGET: '#F05A28',
  CLOSED: '#000',
  CHANGED: '#BE1E2D',
  VIRTUAL: '#c5c4ea',
};

export const COLOR_PALETTES = {
  TRAFFIC_VIEW: ['#009345', '#F9EC31', '#FAAF40', '#F05A28', '#BE1E2D'],
  TRAFFIC_DIFF: customDiffColors || ['#2166AC', '#67A9CF', '#D1E5F0', '#F7F7F7', '#FDDBC7', '#EF8A62', '#BE1E2D'],
  TRAFFIC_DIFF_LONG: [
    '#5e4fa2',
    '#3288bd',
    '#66c2a5',
    '#abdda4',
    '#e6f598',
    '#ffffbf',
    '#fee08b',
    '#fdae61',
    '#f46d43',
    '#d53e4f',
    '#9e0142',
  ],
  TRAFFIC_DIFF_EXTRA_LONG: [
    '#7B3014',
    '#9C3206',
    '#C53E01',
    '#E35408',
    '#F4711F',
    '#FD8E3F',
    '#FDAA65',
    '#D2D2D2',
    '#7AC7E2',
    '#6AB1D6',
    '#4993C0',
    '#2D7DB4',
    '#1C6AA8',
    '#1F5591',
    '#26456E',
  ],
  TRAFFIC_DIFF_EXTRA_LONG2: [
    '#7B3014',
    '#9C3206',
    '#C53E01',
    '#E35408',
    '#F4711F',
    '#FD8E3F',
    '#FDAA65',
    '#96BC85',
    '#7AC7E2',
    '#6AB1D6',
    '#4993C0',
    '#2D7DB4',
    '#1C6AA8',
    '#1F5591',
    '#26456E',
  ],
  SINGLE_COLOR: ['#ffffd4', '#fed98e', '#fe9929', '#d95f0e', '#993404'],
};

export const INTENSITY_COEF = 1;

// Levels counts is 1 less than color count, as first and last color are used for min and max values intervals
export const COLOR_SETS = {
  INTENSITY: { levels: [0.45, 0.65, 0.85, 1], colorMap: COLOR_PALETTES.TRAFFIC_VIEW },
  DIFF: { levels: customDiffLevels || [-500, -250, -50, 50, 250, 500], colorMap: COLOR_PALETTES.TRAFFIC_DIFF },
  SPEED: { levels: [31, 51, 71, 91], colorMap: COLOR_PALETTES.SINGLE_COLOR },
  VOLUMES: { levels: [200, 500, 1000, 2000], colorMap: COLOR_PALETTES.SINGLE_COLOR },
  TRAVEL_TIMES: { levels: [0.001, 0.01, 0.1, 0.2], colorMap: COLOR_PALETTES.SINGLE_COLOR },
  DELAY_TIMES: { levels: [0.001, 0.005, 0.05, 0.2], colorMap: COLOR_PALETTES.TRAFFIC_VIEW },
};

// List of style resolvers for main style properties (width, color, text if applied). These are utilized by getLinkStyleProps function usually (so check this one for more info)
export const LINK_STYLES = {
  [NO_MODE]: {
    WIDTH: {},
    COLOR: {
      intensity: COLOR_SETS.INTENSITY,
    },
  },
  [MP_MODEL_STA_VIEWER]: {
    WIDTH: {},
    COLOR: {
      intensity: COLOR_SETS.INTENSITY,
    },
  },
  [MP_MODEL_DTA_VIEWER]: {
    // Get intensity width formula (which recalc width to zoom levels), which is always used by default getLinkWidth function, has experimental resolution recalc tailored to volume numbers (expected to be 0-3600 in pilsen)
    // As other widths properties are experimental for now, temp solution is to convert its value to volume-like number (with similar min-max and distribution)
    // In many cases formula improvement might be needed if that prop will be used for final viz, as mainly distribution is incorrect
    WIDTH: {
      length: (value) => value * 800,
      freeSpeed: (value) => (value - 20) * 30,
      criticalSpeed: (value) => (value - 20) * 30,
      travelTime: (value) => value * 60 * 800,
      travelSpeed: (value) => (value - 20) * 30,
      outFlowIntensity: (value) => value * 4 * 800,
      freeSpeedTime: (value) => value * 60 * 800,
      criticalSpeedTime: (value) => value * 60 * 800,
      flowDiff: (value) => Math.abs(value) * 10,
      freeSpeedDiff: (value) => Math.abs(value) * 50,
      criticalSpeedDiff: (value) => Math.abs(value) * 50,
      delayFreeSpeed: (value) => value * 10000,
      delayCriticalSpeed: (value) => value * 10000,
    },
    COLOR: {
      length: { levels: [0.2, 0.5, 1, 2], colorMap: COLOR_PALETTES.SINGLE_COLOR },
      freeSpeed: COLOR_SETS.SPEED,
      criticalSpeed: COLOR_SETS.SPEED,
      outFlow: COLOR_SETS.VOLUMES,
      inFlow: COLOR_SETS.VOLUMES,
      travelTime: COLOR_SETS.TRAVEL_TIMES,
      capacity: COLOR_SETS.VOLUMES,
      travelSpeed: COLOR_SETS.SPEED,
      outFlowIntensity: COLOR_SETS.INTENSITY,
      freeSpeedTime: COLOR_SETS.TRAVEL_TIMES,
      criticalSpeedTime: COLOR_SETS.TRAVEL_TIMES,
      flowDiff: {
        levels: [-250, -100, -20, 20, 100, 250],
        colorMap: COLOR_PALETTES.TRAFFIC_DIFF,
      },
      freeSpeedDiff: {
        levels: [-30, -15, -5, 5, 15, 30],
        colorMap: COLOR_PALETTES.TRAFFIC_DIFF,
      },
      criticalSpeedDiff: {
        levels: [-30, -15, -5, 5, 15, 30],
        colorMap: COLOR_PALETTES.TRAFFIC_DIFF,
      },
      delayFreeSpeed: COLOR_SETS.DELAY_TIMES,
      delayCriticalSpeed: COLOR_SETS.DELAY_TIMES,
    },
    TEXT: {
      length: formatFixed,
      travelTime: formatHour,
      travelSpeed: formatRound,
      outFlowIntensity: formatFixed,
      freeSpeedTime: formatHour,
      criticalSpeedTime: formatHour,
      freeSpeedDiff: formatRound,
      criticalSpeedDiff: formatRound,
      delayFreeSpeed: formatHour,
      delayCriticalSpeed: formatHour,
    },
  },
  [MP_MODEL_STA_COMPARISON]: {
    COLOR: {
      volumeDiff: COLOR_SETS.DIFF,
    },
  },
  [MP_MODEL_DTA_COMPARISON]: {
    COLOR: {
      freeSpeedDiff: { levels: [-30, -15, -5, 5, 15, 30], colorMap: COLOR_PALETTES.TRAFFIC_DIFF },
      capacityDiff: COLOR_SETS.DIFF,
      outFlowDiff: COLOR_SETS.DIFF,
      inFlowDiff: COLOR_SETS.DIFF,
      travelTimeDiff: {
        levels: [-0.05, -0.02, -0.005, 0.005, 0.02, 0.05],
        colorMap: COLOR_PALETTES.TRAFFIC_DIFF,
      },
      travelSpeedDiff: { levels: [-30, -15, -5, 5, 15, 30], colorMap: COLOR_PALETTES.TRAFFIC_DIFF },
      outIntensityDiff: { levels: [-0.4, -0.2, -0.1, 0.1, 0.2, 0.4], colorMap: COLOR_PALETTES.TRAFFIC_DIFF },
    },
    TEXT: {
      default: (value) => value.toString(),
      travelTimeDiff: formatHour,
      travelSpeedDiff: formatRound,
      outIntensityDiff: formatFixed,
    },
  },
  [MP_HISTORICAL_COMPARISON]: {
    COLOR: {
      volume: COLOR_SETS.DIFF,
    },
  },
  [MP_H_AGGREGATION_COMPARISON]: {
    COLOR: {
      volume: COLOR_SETS.DIFF,
    },
  },
  [MP_COMBINED_COMPARISON]: {
    TEXT: {
      volumePercentChange: formatFixed,
    },
    COLOR: {
      volumePercentChange: {
        levels: [-100, -50, -40, -30, -20, -10, -5, 5, 10, 20, 30, 40, 50, 100],
        colorMap: COLOR_PALETTES.TRAFFIC_DIFF_EXTRA_LONG,
      },
      // Temporal solution of multiple styles for same data property
      volumePercentChange2: {
        levels: [-100, -50, -40, -30, -20, -10, -5, 5, 10, 20, 30, 40, 50, 100],
        colorMap: COLOR_PALETTES.TRAFFIC_DIFF_EXTRA_LONG2,
      },
    },
  },
  [MP_MODEL_STA_AGGREGATION]: {
    WIDTH: {},
    COLOR: {
      intensity: COLOR_SETS.INTENSITY,
    },
  },
  [MP_MODEL_STA_AGGREGATION_COMPARISON]: {
    COLOR: {
      volumeDiff: COLOR_SETS.DIFF,
    },
  },
  [MP_MODEL_STA_DATASET]: {
    WIDTH: {},
    COLOR: {
      intensity: COLOR_SETS.INTENSITY,
    },
  },
  [MP_DATASET_MATRIX_COMPARISON]: {
    COLOR: {
      volume: COLOR_SETS.DIFF,
    },
  },
  [MP_MATRICES_COMPARISON]: {
    COLOR: {
      volume: COLOR_SETS.DIFF,
    },
  },
};
