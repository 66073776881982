import {
  getSynchronizedModuleState,
  getDataModeByMapMode,
  getWidgetStateIndex,
  getCalendarDate,
  updateCalendarDate,
} from '@store/helpers';
import {
  LID_REAL,
  MP_HISTORICAL_VIEWER,
  MP_LIVE_VIEWER,
  MODEL_STA_LIDS,
  MP_MODEL_STA_VIEWER,
  MODEL_DTA_LIDS,
  MP_MODEL_DTA_VIEWER,
  MP_MODEL_STA_COMPARISON,
  MP_MODEL_DTA_COMPARISON,
  MP_COMBINED_COMPARISON,
  MP_DATASET_MATRIX_COMPARISON,
  MP_MATRICES_COMPARISON,
  getModelModeByModelType,
} from '@keys/index';
import { dateFiltersOptions } from '@composables/useDateFilters';
import type { Commit, Dispatch } from 'vuex';

export type MapItem = [scenarioId?: number | null, eventId?: number | null, modificationId?: number | null];
type CalendarState = { date: TmCalendarDate; mapMode: TmMapMode; modelType: TmModelType };
type WidgetState = { name: string; dataMode: TmDataMode; modelType: TmModelType };
type ItemLevel = 'scenario' | 'event' | 'modification';
export type MapBase = string;
export type Center = TmTrafficModel['center'];
export type Zoom = TmTrafficModel['zoom'];
export type MapStyle = Record<string, Record<string, unknown>>;

const moduleState = {
  mapMode: <TmMapMode>import.meta.env.VITE_DEFAULT_MAP_MODE,
  center: <Center | null>null,
  zoom: <Zoom | null>null,
  item: <MapItem>[],
  dataset: <number | null>null,
  matrix: <number | null>null,
  comparison: <Record<TmMapMode, TmComparisonItems>>{},
  base: <MapBase>import.meta.env.VITE_DEFAULT_MAP_BASE,
  mapStyle: <MapStyle>{
    general: {
      links: true,
      nodes: false,
      generators: false,
      vehiclesCountDisplayed: false,
      parking: false,
      parkingZones: false,
      jsdi: false,
      layerOrder: [], // list of layer order keys representing ascending z-index order - issue TraMod-1245
      trafficSignsStyle: import.meta.env.VITE_TRAFFIC_SIGNS_STYLE === 'true' || false,
      modelLinkOffset: import.meta.env.VITE_MODEL_LINK_OFFSET === 'true' || false,
      highlightedLinks: [], // list of link IDs to be highlighted - issue TraMod-713,
      hideVirtualEdges: import.meta.env.VITE_HIDDEN_VIRTUAL_EDGES === 'true' || false, // hide edges with the 'isvirtual' prop - issue TraMod-1001
      customPalette: null,
    },
    [MP_MODEL_DTA_VIEWER]: {
      output: 'outflow-capacity',
      width: 'outFlow',
      color: 'outFlowIntensity',
      text: 'outFlow',
    },
    [MP_MODEL_DTA_COMPARISON]: {
      color: 'outFlowDiff',
      width: 'outFlowDiff',
    },
    [MP_COMBINED_COMPARISON]: {
      color: 'volumePercentChange',
      width: 'volumeDiff',
    },
  },
  calendar: <CalendarState[]>[],
  dateFilters: <TmDateFilters>{
    months: [...dateFiltersOptions.months],
    days: [...dateFiltersOptions.days],
    hours: [...dateFiltersOptions.hours],
  },
  widgets: <WidgetState[]>[
    { name: 'calendar', dataMode: 'historical', modelType: 'STA' },
    { name: 'calendar', dataMode: 'model', modelType: 'STA' },
    { name: 'calendar', dataMode: 'model', modelType: 'DTA' },
    { name: 'comparison', dataMode: 'comparison', modelType: 'STA' },
    { name: 'comparison', dataMode: 'comparison', modelType: 'DTA' },
  ],
  brokenMapModes: <string[]>[],
  // TODO Temp solution
  sourceLoaded: <{ [key: string]: boolean }>{},
};

export const getters = {
  getCalendarDate:
    (state: typeof moduleState, getters: TmStoreGetters, rootState: TmStoreState) =>
    ({
      mapMode = state.mapMode,
      modelType = rootState.scenarios.model.type,
      startDateOnly = false,
      endDateOnly = false,
      sourceOnly = false,
      targetOnly = false,
    } = {}) => {
      const date = getCalendarDate({ calendarState: state.calendar, mapMode, modelType });
      if (!Array.isArray(date)) return date;
      if (!targetOnly && startDateOnly) return date[0];
      if (!targetOnly && endDateOnly) return date[1];
      if (targetOnly && startDateOnly) return date[2];
      if (targetOnly && endDateOnly) return date[3];
      if (sourceOnly) return [date[0], date[1]];
      if (targetOnly) return [date[2], date[3]];
      return date;
    },
  getDateFilters: (state: typeof moduleState) => state.dateFilters,
  getActiveItemId:
    (state: typeof moduleState) =>
    ({ itemLevel }: { itemLevel: ItemLevel }) => {
      const stateItem = Array.isArray(state.item) ? state.item : [state.item];

      switch (itemLevel) {
        case 'scenario':
          return stateItem[0] ?? null;
        case 'event':
          return stateItem[1] ?? null;
        case 'modification':
          return stateItem[2] ?? null;
      }
    },
  getDataMode: (state: typeof moduleState) => getDataModeByMapMode(state.mapMode),
  getWidgets:
    (state: typeof moduleState, getters: TmStoreGetters, rootState: TmStoreState) =>
    ({ dataMode = getters.getDataMode, modelType = rootState.scenarios.model.type } = {}) =>
      state.widgets.reduce((widgets, props) => {
        if (dataMode == props.dataMode && modelType == props.modelType) widgets.push(props.name);
        return widgets;
      }, <string[]>[]),
  getPreviewedDatasetId: (state: typeof moduleState) => state.dataset || undefined,
  getPreviewedMatrixId: (state: typeof moduleState) => state.matrix || undefined,
  getComparedItems: (state: typeof moduleState) => {
    const mapMode = state.mapMode;
    const hasBaseItem = ![MP_DATASET_MATRIX_COMPARISON, MP_MATRICES_COMPARISON].includes(mapMode);
    let sourceId = state.comparison[mapMode]?.[0];
    let targetId = state.comparison[mapMode]?.[1];
    if (!sourceId && hasBaseItem) sourceId = 'base';
    if (!targetId && hasBaseItem) targetId = 'base';
    return [sourceId, targetId];
  },
  getComparisonSource: (state: typeof moduleState, getters: TmStoreGetters) => getters.getComparedItems[0],
  getComparisonTarget: (state: typeof moduleState, getters: TmStoreGetters) => getters.getComparedItems[1],
};

export const actions = {
  async setMapMode(
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    {
      mode,
      openWidgetPanel = false,
      closeWidgetPanel = false,
    }: { mode: TmMapMode; openWidgetPanel: boolean; closeWidgetPanel: boolean },
  ) {
    commit('SET_MAP_MODE', { mode });
    if (openWidgetPanel) dispatch('layout/openPanel', { panelSide: 'right' }, { root: true });
    if (closeWidgetPanel) dispatch('layout/closePanel', { panelSide: 'right' }, { root: true });
  },
  async setCalendarDate(
    { commit, state, rootState }: { commit: Commit; state: typeof moduleState; rootState: TmStoreState },
    {
      tmDate,
      mapMode = state.mapMode,
      modelType = rootState.scenarios.model.type,
    }: { tmDate: TmDate; mapMode: TmMapMode; modelType: TmModelType },
  ) {
    commit('SET_CALENDAR', { tmDate, mapMode, modelType });
  },
  setDateFilters(
    { commit, state }: { commit: Commit; state: typeof moduleState },
    { months, days, hours }: TmDateFilters,
  ) {
    const newMonths = months ? months : state.dateFilters.months;
    const newDays = days ? days : state.dateFilters.days;
    const newHours = hours ? hours : state.dateFilters.hours;
    commit('SET_DATE_FILTERS', { months: newMonths, days: newDays, hours: newHours });
  },
  async activateItem(
    { commit, rootState }: { commit: Commit; rootState: TmStoreState },
    { scenarioId, eventId, modificationId, date }: TmItemIds & { date?: TmDate } = {},
  ) {
    const modelType = rootState.scenarios.model.type;
    const mapMode = getModelModeByModelType(modelType);
    commit('SET_MAP_MODE', { mode: mapMode }); // map item should always be displayed in its variant model mode
    commit('SET_ITEM', { scenarioId, eventId, modificationId });
    if (modelType === 'STA') commit('SET_COMPARISON', { items: [scenarioId], mapMode: MP_MODEL_STA_COMPARISON }); // activating scenario also puts the active scenario into scenario comparison as comparison source (STA scenario comparison only, requested by some older ticket, before comparison had any modes)
    if (date) commit('SET_CALENDAR', { tmDate: date, mapMode, modelType }); // also update calendar date if set
  },
  async setPosition(
    { commit, state }: { commit: Commit; state: typeof moduleState },
    { center, zoom, defaultOnly = false }: { center: Center; zoom: Zoom; defaultOnly: boolean },
  ) {
    if (!defaultOnly || !state.center) commit('SET_CENTER', { center });
    if (!defaultOnly || !state.zoom) commit('SET_ZOOM', { zoom });
  },
  async zoomIn({ commit, state }: { commit: Commit; state: typeof moduleState }) {
    commit('SET_ZOOM', { zoom: (state.zoom || 0) + 1 });
  },
  async zoomOut({ commit, state }: { commit: Commit; state: typeof moduleState }) {
    commit('SET_ZOOM', { zoom: (state.zoom || 0) - 1 });
  },
  async setBase({ commit }: { commit: Commit }, { baseId }: { baseId: string }) {
    commit('SET_BASE', { baseId });
  },
  async setComparedItems({ commit }: { commit: Commit }, items: TmComparisonItems) {
    commit('SET_COMPARISON', { items });
  },
  async setComparisonSource({ commit, getters }: { commit: Commit; getters: TmStoreGetters }, id: TmComparedItemId) {
    const targetItemId = getters.getComparisonTarget;
    commit('SET_COMPARISON', { items: [id, targetItemId] });
  },
  async setComparisonTarget({ commit, getters }: { commit: Commit; getters: TmStoreGetters }, id: TmComparedItemId) {
    const sourceItemId = getters.getComparisonSource;
    commit('SET_COMPARISON', { items: [sourceItemId, id] });
  },
  async addBrokenMapMode(
    { commit, state }: { commit: Commit; state: typeof moduleState },
    { mapModeKey = null, layerKey = null } = {},
  ) {
    const brokenMapModes = state.brokenMapModes;

    if (mapModeKey) brokenMapModes.push(mapModeKey);

    if (layerKey && layerKey == LID_REAL) {
      brokenMapModes.push(MP_HISTORICAL_VIEWER);
      brokenMapModes.push(MP_LIVE_VIEWER);
    }

    if (layerKey && Object.values(MODEL_STA_LIDS).includes(layerKey)) {
      brokenMapModes.push(MP_MODEL_STA_VIEWER);
    }

    if (layerKey && Object.values(MODEL_DTA_LIDS).includes(layerKey)) {
      brokenMapModes.push(MP_MODEL_DTA_VIEWER);
    }

    commit('SET_BROKEN_MODES', [...new Set(brokenMapModes)]);
  },
  addSourceLoaded({ commit }: { commit: Commit }, { sourceKey, status }: { sourceKey: string; status: boolean }) {
    // TODO Temp solution
    commit('SET_SOURCE_LOADED', { sourceKey, status });
  },
  toggleWidget(
    {
      commit,
      state,
      rootState,
      getters,
    }: { commit: Commit; state: typeof moduleState; rootState: TmStoreState; getters: TmStoreGetters },
    {
      name,
      dataMode = getters.getDataMode,
      modelType = rootState.scenarios.model.type,
    }: {
      name: string;
      dataMode: TmDataMode;
      modelType: TmModelType;
    },
  ) {
    const activeWidgetIndex = getWidgetStateIndex({ widgetsState: state.widgets, name, dataMode, modelType });
    if (activeWidgetIndex >= 0) commit('HIDE_WIDGET', { widgetIndex: activeWidgetIndex });
    else commit('SHOW_WIDGET', { name, dataMode, modelType });
  },
  showWidget(
    {
      commit,
      state,
      rootState,
      getters,
    }: { commit: Commit; state: typeof moduleState; rootState: TmStoreState; getters: TmStoreGetters },
    {
      name,
      dataMode = getters.getDataMode,
      modelType = rootState.scenarios.model.type,
    }: { name: string; dataMode: TmDataMode; modelType: TmModelType },
  ) {
    const activeWidgetIndex = getWidgetStateIndex({ widgetsState: state.widgets, name, dataMode, modelType });
    if (activeWidgetIndex < 0) commit('SHOW_WIDGET', { name, dataMode, modelType });
  },
  hideWidget(
    {
      commit,
      state,
      rootState,
      getters,
    }: { commit: Commit; state: typeof moduleState; rootState: TmStoreState; getters: TmStoreGetters },
    {
      name,
      dataMode = getters.getDataMode,
      modelType = rootState.scenarios.model.type,
    }: { name: string; dataMode: TmDataMode; modelType: TmModelType },
  ) {
    const activeWidgetIndex = getWidgetStateIndex({ widgetsState: state.widgets, name, dataMode, modelType });
    if (activeWidgetIndex >= 0) commit('HIDE_WIDGET', { widgetIndex: activeWidgetIndex });
  },
  async previewDataset({ commit }: { commit: Commit }, datasetId?: number) {
    commit('SET_DATASET', datasetId);
    commit('SET_COMPARISON', { items: [datasetId], mapMode: MP_DATASET_MATRIX_COMPARISON });
  },
  async previewMatrix({ commit }: { commit: Commit }, matrixId?: number) {
    commit('SET_MATRIX', matrixId);
    commit('SET_COMPARISON', { items: [matrixId], mapMode: MP_MATRICES_COMPARISON });
  },
};

export const mutations = {
  SET_MAP_MODE(state: typeof moduleState, { mode }: { mode: TmMapMode }) {
    state.mapMode = mode;
  },
  SET_CALENDAR(
    state: typeof moduleState,
    { tmDate, mapMode, modelType }: { tmDate: TmDate; mapMode: TmMapMode; modelType: TmModelType },
  ) {
    updateCalendarDate({ calendarState: state.calendar, date: tmDate, mapMode, modelType });
  },
  SET_DATE_FILTERS(state: typeof moduleState, { months, days, hours }: TmDateFilters) {
    // set object values one by one to avoid triggering unexpected ref changes during watches
    state.dateFilters.months = months;
    state.dateFilters.days = days;
    state.dateFilters.hours = hours;
  },
  SET_ITEM(state: typeof moduleState, { scenarioId, eventId, modificationId }: TmItemIds = {}) {
    if (!scenarioId && !eventId && !modificationId) return (state.item = []); // keep empty array, unless there is at least one item active
    // set array values one by one to avoid triggering unexpected ref changes during watches
    state.item[0] = scenarioId || null;
    state.item[1] = eventId || null;
    state.item[2] = modificationId || null;
  },
  SET_CENTER(state: typeof moduleState, { center }: { center: Center }) {
    state.center = center;
  },
  SET_ZOOM(state: typeof moduleState, { zoom }: { zoom: Zoom }) {
    state.zoom = zoom;
  },
  SET_BASE(state: typeof moduleState, { baseId }: { baseId: string }) {
    state.base = baseId;
  },
  SET_COMPARISON(
    state: typeof moduleState,
    { mapMode = state.mapMode, items }: { mapMode: TmMapMode; items: TmComparisonItems },
  ) {
    // NOTE: nulls are propagated to query, undefined is not (prevents issue with query syncing when only the comparison target is selected)
    state.comparison[mapMode] = [items[0] || null, items[1] || null];
  },
  SET_BROKEN_MODES(state: typeof moduleState, brokenMapModes: string[]) {
    state.brokenMapModes = brokenMapModes;
  },
  SET_MAP_STYLE(
    state: typeof moduleState,
    {
      newState,
      group,
      property,
    }: {
      newState: never;
      group: keyof typeof moduleState.mapStyle;
      property: never; // TS_TODO:
    },
  ) {
    state.mapStyle[group][property] = newState;
  },
  SET_SOURCE_LOADED(
    state: typeof moduleState,
    { sourceKey, status }: { sourceKey: keyof typeof moduleState.sourceLoaded; status: boolean },
  ) {
    // TODO Temp solution
    state.sourceLoaded[sourceKey] = status;
  },
  SHOW_WIDGET(
    state: typeof moduleState,
    { name, dataMode, modelType }: { name: string; dataMode: TmDataMode; modelType: TmModelType },
  ) {
    state.widgets.push({ name, dataMode, modelType });
  },
  HIDE_WIDGET(state: typeof moduleState, { widgetIndex }: { widgetIndex: number }) {
    state.widgets.splice(widgetIndex, 1);
  },
  SET_DATASET(state: typeof moduleState, datasetId?: number) {
    state.dataset = datasetId || null;
  },
  SET_MATRIX(state: typeof moduleState, matrixId?: number) {
    state.matrix = matrixId || null;
  },
};

export default {
  namespaced: true,
  state: getSynchronizedModuleState({
    module: 'map',
    state: moduleState,
  }),
  getters,
  actions,
  mutations,
};
