import useMainApiRequest from '@composables/useMainApiRequest';
import { useI18n } from 'vue-i18n';
import useToast from '@composables/useToast';
import { ref } from 'vue';
import GeoJSON from 'ol/format/GeoJSON.js';
import type { FeatureCollection } from 'geojson';

const jsdiGeojson = ref<FeatureCollection>(); // NOTE: this ref is intentionally defined globally outside the composable scope, otherwise, when used by the jsdi layer as a 'featuresVModel' computed, it does not appear to be reactive (TODO: try to solve, possibly vuemap issue)
const fetchJsdiPromise = ref<Promise<boolean | void>>();
const updateJsdiPromise = ref<Promise<boolean | void>>();
const lastUpdateTimestamp = ref<number | undefined>(Date.now());

export default function useJsdi() {
  const { makeRequest } = useMainApiRequest();
  const { t } = useI18n();
  const { addToast } = useToast();

  const getOlFeatures = () => {
    if (!jsdiGeojson.value?.features?.length) return [];
    // transform geojson features to OL features
    return new GeoJSON().readFeatures(jsdiGeojson.value, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857',
    });
  };

  const fetchJsdiData = () => {
    if (fetchJsdiPromise.value) return fetchJsdiPromise.value;

    const fetchAction = () =>
      makeRequest({
        url: `jsdi`,
        method: 'get',
        message: { error: t(`map.error while fetching JSDI traffic situations`) },
        onSuccess: (result: { jsdi: FeatureCollection }) => {
          jsdiGeojson.value = result.jsdi;
        },
      });

    // re-use unresolved promise
    return (fetchJsdiPromise.value = fetchAction().then(
      () => (fetchJsdiPromise.value = undefined), // delete resolved promise
    ));
  };

  const updateJsdiData = (onChangeCallback?: () => void) => {
    if (updateJsdiPromise.value) return updateJsdiPromise.value;
    const isAutoUpdate = !onChangeCallback;
    if (!isAutoUpdate && !hasTimestampExpired(lastUpdateTimestamp.value)) return; // do nothing if update has been executed recently
    if (!isAutoUpdate) addToast({ severity: 'info', summary: t('map.jsdi update initialized') });

    const updateAction = () =>
      makeRequest({
        url: `jsdi/update`,
        method: 'post',
        useLoader: !isAutoUpdate,
        timeout: 1000 * 30, // 30 seconds
        message: { error: t(`map.error while updating JSDI traffic situations`) },
        onSuccess: (data, status) => {
          if (status === 204) return; // no changes has been made
          lastUpdateTimestamp.value = Date.now();
          if (!onChangeCallback) return;
          onChangeCallback();
        },
      });

    // re-use unresolved promise
    return (updateJsdiPromise.value = updateAction().then(
      () => (updateJsdiPromise.value = undefined), // delete resolved promise
    ));
  };

  return { getOlFeatures, fetchJsdiData, updateJsdiData, invalidateJsdiData };
}

function hasTimestampExpired(timestamp?: number) {
  if (!timestamp) return true;
  const currentTime = Date.now();
  const fiveMinutesAgo = currentTime - 5 * 60 * 1000;
  return timestamp < fiveMinutesAgo;
}

function invalidateJsdiData() {
  jsdiGeojson.value = undefined;
  lastUpdateTimestamp.value = undefined;
}
