import { getSynchronizedModuleState } from '@store/helpers';
import { set } from 'lodash-es';
import type { Commit } from 'vuex';
import type { Filters, Sorting } from '@composables/useFilters';

type ScenarioModel = Partial<TmTrafficModel>;
type ScenarioProgress = {
  isToBeSaved?: boolean;
  isToBeComputed?: boolean;
  isBeingComputed?: boolean;
  hasComputationReady?: boolean;
  hasComputationError?: boolean;
  computationTimestamp?: TmDate;
};
type EditMode = {
  scenarioId: number | null;
  isOpenSession: boolean;
  isUsingChangedScenario: boolean;
};

const CACHE_MINUTE_TIMEOUT = 15;

const moduleState = {
  model: <ScenarioModel>{
    name: import.meta.env.VITE_DEFAULT_TRAFFIC_MODEL,
    isBroken: false,
    isValid: true,
  },
  progress: <Record<string, ScenarioProgress>>{},
  editMode: <EditMode>{
    scenarioId: null,
    isOpenSession: false,
    isUsingChangedScenario: false,
  },
  filters: <Record<string, Filters>>{},
  sorting: <Record<string, Sorting>>{},
  cache: {
    scenarios: { enabled: true, timer: Date.now() },
    events: { enabled: true, timer: Date.now() },
    modifications: { enabled: true, timer: Date.now() },
    nodeTransit: { enabled: true, timer: Date.now() },
    scenariosOverview: { enabled: true, timer: Date.now() },
  },
};

export const getters = {
  isCacheExpired: (state: typeof moduleState) => (cacheKey: keyof typeof moduleState.cache) => {
    return state.cache[cacheKey].timer + CACHE_MINUTE_TIMEOUT * 60 * 1000 < Date.now();
  },
  isWatchModeActive: (state: typeof moduleState, getters: TmStoreGetters) => (scenarioId: number) => {
    const isOpenEditSession = !!getters.isOpenEditSession(scenarioId);
    const isBeingComputed = !!state.progress[scenarioId]?.isBeingComputed;
    const hasComputationReady = !!state.progress[scenarioId]?.hasComputationReady;
    const isModelValid = !!state.model?.isValid;
    return !isOpenEditSession || isBeingComputed || hasComputationReady || !isModelValid;
  },
  isDtaModelTypeActive: (state: typeof moduleState) => {
    return state.model.type === 'DTA';
  },
  isScenarioToBeSaved: (state: typeof moduleState) => (scenarioId: number) => {
    return !!state.progress[scenarioId]?.isToBeSaved;
  },
  isScenarioToBeComputed: (state: typeof moduleState) => (scenarioId: number) => {
    return !!state.progress[scenarioId]?.isToBeComputed || state.progress[scenarioId]?.hasComputationError; // enforce computation even if there are no computation changes, but the editSession has error state for any reason (Issue TraMod-1167)
  },
  isScenarioBeingComputed: (state: typeof moduleState) => (scenarioId: number) => {
    return !!state.progress[scenarioId]?.isBeingComputed;
  },
  hasScenarioComputationReady: (state: typeof moduleState) => (scenarioId: number) => {
    return !!state.progress[scenarioId]?.hasComputationReady;
  },
  hasScenarioComputationError: (state: typeof moduleState) => (scenarioId: number) => {
    return !!state.progress[scenarioId]?.hasComputationError;
  },
  getScenarioComputationTimestamp: (state: typeof moduleState, getters: TmStoreGetters) => (scenarioId: number) => {
    if (!getters.isScenarioUnresolved(scenarioId)) return null;
    return state.progress[scenarioId]?.computationTimestamp || null;
  },
  isScenarioUnresolved: (state: typeof moduleState) => (scenarioId: number) => {
    const isBeingComputed = !!state.progress[scenarioId]?.isBeingComputed;
    const hasComputationReady = !!state.progress[scenarioId]?.hasComputationReady;
    const hasComputationError = !!state.progress[scenarioId]?.hasComputationError;
    return isBeingComputed || hasComputationReady || hasComputationError;
  },
  getUnresolvedScenariosCount: (state: typeof moduleState, getters: TmStoreGetters) => {
    let unresolvedScenariosCount = 0;
    for (const scenarioId of Object.keys(state.progress)) {
      const isScenarioUnresolved = !!getters.isScenarioUnresolved(scenarioId);
      if (isScenarioUnresolved) unresolvedScenariosCount++;
    }
    return unresolvedScenariosCount;
  },
  isOpenEditSession: (state: typeof moduleState) => (scenarioId: number) => {
    if (state.editMode.scenarioId != scenarioId) return false;
    return state.editMode.isOpenSession;
  },
  isUsingChangedScenario: (state: typeof moduleState) => (scenarioId?: number) => {
    if (!state.editMode.scenarioId) return false;
    if (scenarioId && state.editMode.scenarioId != scenarioId) return false;
    return state.editMode.isUsingChangedScenario;
  },
  isInsideComputation: (state: typeof moduleState, getters: TmStoreGetters) => (scenarioId: number) => {
    const isUsingChangedScenario = !!getters.isUsingChangedScenario(scenarioId);
    const isBeingComputed = !!state.progress[scenarioId]?.isBeingComputed;
    const hasComputationReady = !!state.progress[scenarioId]?.hasComputationReady;
    return isUsingChangedScenario && (isBeingComputed || hasComputationReady);
  },
  isWithComputation: (state: typeof moduleState, getters: TmStoreGetters) => (scenarioId: number) => {
    const isOpenEditSession = !!getters.isOpenEditSession(scenarioId);
    const isBeingComputed = !!state.progress[scenarioId]?.isBeingComputed;
    const hasComputationReady = !!state.progress[scenarioId]?.hasComputationReady;
    return isOpenEditSession && (isBeingComputed || hasComputationReady);
  },
};

export const actions = {
  startEditMode(
    { commit }: { commit: Commit },
    { scenarioId, withChanges = false }: { scenarioId: number; withChanges?: boolean },
  ) {
    commit('SET_EDIT_MODE', { scenarioId, isOpenSession: true, isUsingChangedScenario: withChanges });
  },
  exitEditMode({ commit }: { commit: Commit }, { scenarioId }: { scenarioId?: number } = {}) {
    commit('SET_EDIT_MODE', { scenarioId: null, isOpenSession: false, isUsingChangedScenario: false });
    if (scenarioId) commit('SET_PROGRESS', { scenarioId, isToBeSaved: false, isToBeComputed: false });
    else commit('CLEAR_PROGRESS');
  },
  switchToChangedScenario({ commit }: { commit: Commit }) {
    commit('SET_EDIT_MODE', { isUsingChangedScenario: true });
  },
  switchToOriginalScenario({ commit }: { commit: Commit }) {
    commit('SET_EDIT_MODE', { isUsingChangedScenario: false });
  },
  updateScenarioProgress(
    { commit }: { commit: Commit },
    { scenarioId, editSession }: { scenarioId: number; editSession: TmEditSession },
  ) {
    const computationState = editSession.computationState || null;
    const computationTimestamp = editSession.computationTimestamp || null;
    const isBeingComputed = computationState === 'inProgress';
    const hasComputationReady = computationState === 'computed';
    const hasComputationError = computationState === 'error';
    const isToBeComputed = !isBeingComputed && !hasComputationReady && !!editSession.hasComputableChange;
    const isToBeSaved = !isBeingComputed && !!editSession.hasChange;

    commit('SET_PROGRESS', {
      scenarioId,
      isToBeComputed,
      isToBeSaved,
      isBeingComputed,
      hasComputationReady,
      hasComputationError,
      computationTimestamp,
    });
  },
};

export const mutations = {
  SET_EDIT_MODE(state: typeof moduleState, { scenarioId, isOpenSession, isUsingChangedScenario }: Partial<EditMode>) {
    if (typeof scenarioId !== 'undefined') state.editMode.scenarioId = scenarioId;
    if (typeof isOpenSession !== 'undefined') state.editMode.isOpenSession = isOpenSession;
    if (typeof isUsingChangedScenario !== 'undefined') state.editMode.isUsingChangedScenario = isUsingChangedScenario;
  },
  SET_FILTERS(
    state: typeof moduleState,
    { cacheKey, filters }: { cacheKey: keyof typeof moduleState.filters; filters: Filters },
  ) {
    set(state.filters, cacheKey, filters);
  },
  SET_SORTING(
    state: typeof moduleState,
    { cacheKey, sortBy }: { cacheKey: keyof typeof moduleState.sorting; sortBy: Sorting },
  ) {
    set(state.sorting, cacheKey, sortBy);
  },
  RESET_CACHE_TIMER(state: typeof moduleState, { cacheKey }: { cacheKey: keyof typeof moduleState.cache }) {
    state.cache[cacheKey].timer = Date.now();
  },
  SET_MODEL(
    state: typeof moduleState,
    {
      id,
      activeMatrixId,
      type,
      timestamp,
      isValid,
      isBroken,
      name,
      endPoint,
      center,
      zoom,
      linkOffset,
    }: Partial<ScenarioModel>,
  ) {
    if (id) state.model.id = id;
    if (activeMatrixId) state.model.activeMatrixId = activeMatrixId;
    if (type) state.model.type = type;
    if (name) state.model.name = name;
    if (endPoint) state.model.endPoint = endPoint;
    if (center) state.model.center = center;
    if (zoom) state.model.zoom = zoom;
    if (linkOffset) state.model.linkOffset = linkOffset;
    if (typeof timestamp !== 'undefined') state.model.timestamp = timestamp;
    if (typeof isValid !== 'undefined') state.model.isValid = isValid;
    if (typeof isBroken !== 'undefined') state.model.isBroken = isBroken;
  },
  SET_PROGRESS(
    state: typeof moduleState,
    {
      scenarioId,
      isToBeSaved,
      isToBeComputed,
      isBeingComputed,
      hasComputationReady,
      hasComputationError,
      computationTimestamp,
    }: ScenarioProgress & { scenarioId: number },
  ) {
    if (typeof isToBeSaved !== 'undefined') set(state.progress, `${scenarioId}.isToBeSaved`, isToBeSaved);
    if (typeof isToBeComputed !== 'undefined') set(state.progress, `${scenarioId}.isToBeComputed`, isToBeComputed);
    if (typeof isBeingComputed !== 'undefined') set(state.progress, `${scenarioId}.isBeingComputed`, isBeingComputed);
    if (typeof hasComputationReady !== 'undefined')
      set(state.progress, `${scenarioId}.hasComputationReady`, hasComputationReady);
    if (typeof hasComputationError !== 'undefined')
      set(state.progress, `${scenarioId}.hasComputationError`, hasComputationError);
    if (typeof computationTimestamp !== 'undefined')
      set(state.progress, `${scenarioId}.computationTimestamp`, computationTimestamp);
  },
  CLEAR_PROGRESS(state: typeof moduleState) {
    state.progress = {};
  },
};

export default {
  namespaced: true,
  state: getSynchronizedModuleState({
    module: 'scenarios',
    state: moduleState,
  }),
  getters,
  actions,
  mutations,
};
