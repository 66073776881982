import { shallowRef, markRaw, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { formatAsApiDate, subtractTmDateHours, addTmDateHours, today, isAfter } from '@utils/tm-date';
import useForeignApiRequest from '@composables/useForeignApiRequest';
import { getBaseModelCacheKey } from '@utils/model-sections';
import useModels from '@composables/useModels';
import {
  MP_MODEL_STA_VIEWER,
  MP_MODEL_DTA_VIEWER,
  MP_LIVE_VIEWER,
  MP_HISTORICAL_VIEWER,
  COMPARISON_MAP_MODES,
  MODEL_MAP_MODES,
  getModelModeByModelType,
  MP_HISTORICAL_AGGREGATION,
  AGGREGATION_MAP_MODES,
} from '@keys/index';

const REALTIME_INTERVALS_TO_HOUR = 40;
const DTA_TIME_INTERVALS_NUM = 12; // Might get dynamic later?, Or parse from result
const LATEST_TRAFFIC_REQUEST_LIMIT = 5;

// the traffic is either kept as object collection with pairs of { [edge_id]: [volume/flow value] }  (sta model, historical)
// or { [edge_id]: { volume: [volume/flow value], sensorTime: [sensor_time] }} (live)
// or { [edge_id]: { inFlow: [value], outFlow: [value], ... } (dta)
// or { [edge_id]: { volume: [value], flowSum: [value], hourSum: [value]} (aggregation)
// the format must be compatible with the vuemap layer context (passed via layer.context.add)
const TRAFFIC_PARSER = {
  [MP_MODEL_STA_VIEWER]: (data) => {
    const trafficData = data.result.traffic;
    const output = {};
    for (let i = 0; i < trafficData.length; i++) {
      const item = trafficData[i];
      if (item.edge_id && item.traffic) output[item.edge_id] = item.traffic;
    }
    return output;
  },
  [MP_MODEL_DTA_VIEWER]: (data) => {
    const trafficData = data.result;
    const output = {};
    for (const item of trafficData.result) {
      output[item.edgeId] = item;
    }
    output.modelInfo = { ...trafficData.modelInfo, numOfTimeIntervals: DTA_TIME_INTERVALS_NUM };
    return output; // transform to similar STA structure so extraFeatures code can be reused, parse into hours on demand in DTA composable
  },
  [MP_LIVE_VIEWER]: (data) =>
    data.reduce(
      (parsedData, item) =>
        item.profile_id && item.flow
          ? {
              ...parsedData,
              [item.profile_id]: {
                volume: item.flow * REALTIME_INTERVALS_TO_HOUR,
                sensorTime: addTmDateHours(item.time, 2), // / time from the sensors API is manually increased by 2 - issue TraMod-962
              },
            }
          : parsedData,
      {},
    ),
  [MP_HISTORICAL_VIEWER]: (data) =>
    data.features.reduce((parsedData, item) => {
      const id = item.properties?.id;
      const flow = item.properties?.values[0]?.flow;
      return id && flow ? { ...parsedData, [id]: parseInt(flow) } : parsedData;
    }, {}),
  [MP_HISTORICAL_AGGREGATION]: (data, groupedFlowDivider = 1) =>
    data.features.reduce((parsedData, item) => {
      const id = item.properties?.id;
      const flowSum = parseInt(item.properties?.values[0]?.flow);
      const flowByHour = parseInt(flowSum / groupedFlowDivider);
      return id && flowSum
        ? { ...parsedData, [id]: { volume: flowByHour, flowSum, hourSum: groupedFlowDivider } } // volume is like magic keyword for vuemap layer - it will either display simple value or volume property
        : parsedData;
    }, {}),
};

const TRAFFIC_PARAMS = {
  [MP_HISTORICAL_VIEWER]: ({ date } = {}) => {
    const apiDate = formatAsApiDate(date);
    return {
      start_time: apiDate,
      end_time: apiDate,
      group_by: 'True', // with group_by=True, we are missing 'obs_count' and 'occup' in the response, but the request is much faster - issue TraMod-1015
      geometry_type: 'Point',
      return_geometry: 'False',
    };
  },
  [MP_HISTORICAL_AGGREGATION]: ({ dateFrom, dateTo } = {}) => {
    const startTime = formatAsApiDate(dateFrom);
    const endTime = formatAsApiDate(dateTo);
    return {
      start_time: startTime,
      end_time: endTime,
      group_by: 'True',
      geometry_type: 'Point',
      return_geometry: 'False',
    };
  },
};

const trafficCache = shallowRef({});
const fetchPromises = ref({}); // re-usable promises in case multiple components want to fetch the same data at the same time

export default function useTrafficData(mapModeKey) {
  if (!mapModeKey) throw Error('Map mode key not provided');

  const { makeRequest } = useForeignApiRequest();
  const store = useStore();
  const { fetchModels, activeModel } = useModels();

  async function fetchTrafficData(fetchOptions = {}) {
    const { params, modelCacheKey, parserOptions, cacheKey = mapModeKey } = fetchOptions; // destructed here instead in the function argument to avoid ts errors when called directly from ts files
    const promiseKey = `${mapModeKey}_${cacheKey}`;
    const url = await _getTrafficEndpoint(mapModeKey, modelCacheKey);

    const fetchAction = async () =>
      makeRequest({
        url,
        method: 'get',
        ...(TRAFFIC_PARAMS[mapModeKey] && { params: TRAFFIC_PARAMS[mapModeKey](params) }),
        message: {
          error: `FAILED TO LOAD MAP TRAFFIC DATA (${modelCacheKey || mapModeKey})`,
        },
        timeout: 1000 * 60, // 1 minute
        onSuccess: (result) => {
          const data = TRAFFIC_PARSER[mapModeKey](result, parserOptions);
          trafficCache.value[cacheKey] = markRaw(data);
        },
        onFailure: (err) => {
          console.log(err);
          const isBaseModelError = !modelCacheKey && MODEL_MAP_MODES.includes(mapModeKey);
          if (isBaseModelError) store.dispatch('map/addBrokenMapMode', { mapModeKey });
        },
      });

    if (!fetchPromises.value?.[promiseKey]) {
      fetchPromises.value[promiseKey] = fetchAction().then(() => {
        delete fetchPromises.value[promiseKey]; // delete resolved promise
      });
    }

    // re-use unresolved promises
    return fetchPromises.value[promiseKey];
  }

  const getTrafficDataByKey = (cacheKey = mapModeKey) => trafficCache.value[cacheKey];

  const areTrafficDataAvailable = (cacheKey = mapModeKey) => {
    const trafficData = getTrafficDataByKey(cacheKey);
    return !!trafficData && Object.keys(trafficData).length !== 0;
  };

  const _getTrafficEndpoint = async (mapModeKey, modelCacheKey) => {
    if (MP_LIVE_VIEWER == mapModeKey) return import.meta.env.VITE_API_TRAFFIC_DATA_LIVE;
    if ([MP_HISTORICAL_VIEWER, MP_HISTORICAL_AGGREGATION].includes(mapModeKey))
      return import.meta.env.VITE_API_TRAFFIC_REAL;
    await fetchModels(); // ensure that models are fetched, so the active model is set & accessible
    const { name, endPoint, activeMatrixId } = activeModel.value;
    if (!name || !endPoint) throw Error(`No active model set yet`);
    const urlCacheKey = modelCacheKey || getBaseModelCacheKey(activeMatrixId);
    if ([MP_MODEL_STA_VIEWER, MP_MODEL_DTA_VIEWER].includes(mapModeKey))
      return `${endPoint}/caches/${name}/${urlCacheKey}`;
    throw Error(`TrafficKey ${mapModeKey} is not supported`);
  };

  function clearTrafficData(cacheKey = mapModeKey) {
    trafficCache.value[cacheKey] = undefined;
  }

  return {
    fetchTrafficData,
    getTrafficDataByKey,
    areTrafficDataAvailable,
    clearTrafficData,
    getBaseModelCacheKey,
    // trafficData, // when accessed directly (former computed), reactivity breaks -> access via getTrafficDataByKey is advised
    isLoading: computed(() => Object.keys(fetchPromises.value).length),
  };
}

// Cache of cacheKey + scenarioId data entries
// 'COMMON' means no codeList entries so pull baseData from common traffic data
// cacheKey: []{}codeListString, traffic
const modelTrafficCache = {
  STA: shallowRef({}),
  DTA: shallowRef({}),
};

export function useModelTrafficData(modelType) {
  if (!['STA', 'DTA'].includes(modelType)) throw Error(`Data for model type '${modelType}' are not supported`);

  const mapModeKey = getModelModeByModelType(modelType); // NOTE: isAggregation argument is not passed here - so the functionality for model modeModelSTAViewer is the same as modeModelSTAAggregation (PARAMS and PARSER options are shared between the two map modes for now)
  const modelTypeCache = modelTrafficCache[modelType];
  const commonTrafficData = useTrafficData(mapModeKey);

  const getTrafficDataByKey = ({ cacheKey = mapModeKey, codeList = undefined } = {}) => {
    const trafficData = commonTrafficData.getTrafficDataByKey(cacheKey);
    if (!codeList || Object.keys(codeList).length === 0) return trafficData;

    const modelCacheEntry =
      getCacheItem({ cacheKey, codeList }) ?? parseCodeListTrafficData({ cacheKey, codeList, trafficData });
    return { ...trafficData, ...modelCacheEntry };
  };

  function parseCodeListTrafficData({ cacheKey, codeList, trafficData } = {}) {
    const codeListData = {};
    for (const [modelId, extraId] of Object.entries(codeList)) {
      codeListData[extraId] = trafficData[modelId];
    }

    setCacheItem({ cacheKey, codeList, traffic: codeListData });
    return codeListData;
  }

  function getCacheItem({ cacheKey, codeList }) {
    const entries = modelTypeCache.value[cacheKey];
    if (!entries || entries.length === 0) return null;

    const codeListString = JSON.stringify(codeList);
    const codeListEntry = entries.find((entry) => entry.codeListString === codeListString);
    return codeListEntry?.traffic;
  }

  function setCacheItem({ cacheKey, traffic, codeList }) {
    const codeListString = JSON.stringify(codeList);
    if (!modelTypeCache.value[cacheKey]) modelTypeCache.value[cacheKey] = [];
    modelTypeCache.value[cacheKey].push({ traffic, codeListString });
  }

  return { ...commonTrafficData, getTrafficDataByKey };
}

let latestHistoricalDate = null; // the latest historical date that was fetched and available, stored to be checked when the fetching traffic on the same date again - it is due to the API behavior that can sometimes return un-complete data

export function useHistoricalTrafficData(mapMode) {
  if (![MP_HISTORICAL_VIEWER, MP_HISTORICAL_AGGREGATION].includes(mapMode))
    throw Error(`Data for model type '${mapMode}' are not supported`);

  const store = useStore();
  const commonTrafficData = useTrafficData(mapMode);
  const { fetchTrafficData, areTrafficDataAvailable } = commonTrafficData;

  async function loadLatestAvailableTraffic() {
    let requestsCounter = 0;
    let subtractedDate = today({ stripHours: false, stripMins: true });
    let trafficFound = false;

    while (!trafficFound && requestsCounter < LATEST_TRAFFIC_REQUEST_LIMIT) {
      console.log('Auto fetching latest available historical traffic on date', subtractedDate);
      await fetchTrafficData({ params: { date: subtractedDate }, cacheKey: subtractedDate });
      trafficFound = areTrafficDataAvailable(subtractedDate);
      if (!trafficFound) {
        requestsCounter++;
        subtractedDate = subtractTmDateHours(subtractedDate, 1);
      }
    }

    return trafficFound ? subtractedDate : null;
  }

  async function updateDateToLatest({ date, mapMode, isTargetDate = false } = {}) {
    if (AGGREGATION_MAP_MODES.includes(mapMode))
      throw new Error('Can not fetch latest available traffic in aggregation modes'); // Not suitable for aggregation map modes

    latestHistoricalDate = await loadLatestAvailableTraffic();
    if (!latestHistoricalDate) return date;
    if (!isAfter(latestHistoricalDate, date)) return date;

    const calendarDate = store.getters['map/getCalendarDate']({ mapMode });
    // set calendar to the 'last available traffic' date and time
    const updatedDate = COMPARISON_MAP_MODES.includes(mapMode)
      ? [isTargetDate ? calendarDate[0] : latestHistoricalDate, isTargetDate ? latestHistoricalDate : calendarDate[1]]
      : latestHistoricalDate;
    // it is intended to specify the mapMode here, since user can switch map mode before this dispatch is executed (after the latest traffic is found)
    store.dispatch('map/setCalendarDate', { mapMode, tmDate: updatedDate });
    return Array.isArray(updatedDate) ? updatedDate[isTargetDate ? 1 : 0] : updatedDate;
  }

  async function fetchHistoricalTrafficData(fetchOptions) {
    await fetchTrafficData(fetchOptions);
    const { date } = fetchOptions.params;
    // update latest date if requested date is later and it has already been set (meaning the latest available traffic has been searched for)
    const shouldBeTheLatestDate = latestHistoricalDate && isAfter(latestHistoricalDate, date);
    if (shouldBeTheLatestDate && areTrafficDataAvailable(date)) latestHistoricalDate = date;
  }

  // refetch of last available traffic recommended (it might not be complete at the time)
  // TODO: might be useful to get some kind of 'areDataComplete' from the API
  // const shouldFetchTraffic = (date) => !areTrafficDataAvailable(date) || date === latestHistoricalDate;
  // TODO: this caused too much redundant fetches - either just keep the latest date cached even if it is possibly un-complete or add some kind of button to historical date inputs which only appears if date == latestHistoricalDate, that will manually refetch the date
  // TODO: if it is decided not to handle un-complete traffic - remove all latestHistoricalDate variables and logic from this file
  const shouldFetchTraffic = (date) => !areTrafficDataAvailable(date);

  return {
    ...commonTrafficData,
    updateDateToLatest,
    fetchTrafficData: fetchHistoricalTrafficData,
    shouldFetchTraffic,
    loadLatestAvailableTraffic, // exported for testing
  };
}

// Full clear state for testing
export const clearTrafficDataState = () => {
  trafficCache.value = {};
  latestHistoricalDate = null;
};
