<template>
  <a
    v-if="statusIcon"
    v-tooltip="$t('scenarios.computation')"
    :class="{ hoverable: !!isHoverable }"
    @click="emit('click')"
    ><i :class="statusIcon" class="status-icon"
  /></a>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { TmStatus } from '@composables/useStatus';
import useStatus, { type ItemWithStatus } from '@composables/useStatus';

const props = defineProps<{
  /** scenario or item object with status attribute  */
  item: ItemWithStatus<{ status: string }> | TmScenario;
  /** resource type (e.g. scenarios) */
  itemType: TmItemType;
  /** wether the anchor element should be highlighted on hover */
  isHoverable?: Boolean;
}>();

const emit = defineEmits<{
  /** fires when the anchor element is clicked */
  click: [];
}>();

const { getTmStatus } = useStatus();

const statusIcon = computed(() => {
  const tmStatus = getTmStatus(props.item, props.itemType);
  return getIcon(tmStatus);
});

const icons = {
  computed: 'ri-checkbox-circle-line',
  computing: 'ri-time-line',
  initialized: 'ri-restart-line',
  error: 'ri-close-circle-line',
};

const getIcon = (tmStatus: TmStatus): string | null => {
  if (tmStatus === TmStatus.Computed) return icons.computed;
  if (tmStatus === TmStatus.Computing) return icons.computing;
  if (tmStatus === TmStatus.Initialized) return icons.initialized;
  if (tmStatus === TmStatus.Error) return icons.error;
  return null; // display no icon
};
</script>

<style scoped>
.status-icon {
  color: #0094d2;
  font-size: 1.5rem;
  font-weight: normal;
}
.hoverable .status-icon:hover {
  font-weight: bold;
}
</style>
