<template>
  <div class="p-inputgroup">
    <span
      v-if="label"
      class="p-button button-label"
      :class="[borderColor ? 'border-color-' + borderColor : '', { active }, { disabled }]"
      @click="$emit('label:clicked')"
      >{{ label }}</span
    >
    <prime-input-number
      v-if="type === 'number' && (typeof selection === 'number' || selection === null)"
      v-model="selection"
      :input-props="{ name }"
      :mode="decimalDigits ? 'decimal' : undefined"
      locale="cs-CZ"
      :min-fraction-digits="decimalDigits || 0"
      :max-fraction-digits="decimalDigits || 0"
      :disabled="disabled"
      :readonly="!directInput"
      :suffix="suffix ? ' ' + suffix : undefined"
      :placeholder="placeholder"
      :use-grouping="false"
    />
    <prime-input-text
      v-else-if="typeof selection === 'string' || selection === null"
      v-model="selection"
      :name="name"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="!directInput"
      :maxlength="255"
    />
    <prime-button
      v-if="restorableTo !== null && restorableTo != selection"
      :disabled="disabled"
      icon="pi pi-undo"
      class="p-button clear-icon"
      @click="
        selection = restorableTo;
        $emit('restore:clicked');
      "
    />
    <prime-button
      v-if="clearable && !isEmptySelection"
      :disabled="disabled"
      icon="pi pi-times"
      class="p-button clear-icon"
      @click="clearSelection()"
    />
  </div>
  <small :id="'help-input-' + name" class="p-error" :class="{ disabled }">{{ errorMessage }}</small>
</template>

<script setup lang="ts">
import { watch, computed } from 'vue';
import PrimeButton from 'primevue/button';
import PrimeInputText from 'primevue/inputtext';
import PrimeInputNumber from 'primevue/inputnumber';
import { useField } from 'vee-validate';

type SelectorVal = string | number | null;
type Selector = SelectorVal | SelectorVal[]; // input can also be specified as an array of values, that is then transformed to single string

const props = withDefaults(
  defineProps<{
    name: string; // Name attribute of the input
    type?: 'number' | 'array' | 'string'; // Type of the value (switches between number and text input)
    rules?: string; // Vee-validate rules string (e.g. "required|min:5")
    selected?: Selector; // The value to display as selection
    label?: string; // Label to display before the multi-input
    clearable?: boolean; // Whether to display icon for clearing the multi-input value
    restorableTo?: Selector; // Value to be restored to after clicking on restore icon (also displays the restore icon if not null)
    borderColor?: 'red' | 'green' | 'yellow' | 'blue'; // Color of the label border
    active?: boolean; // Whether this selector is currently 'in use'
    disabled?: boolean; // Disabled state of the whole input selector
    directInput?: boolean; // Whether the input value can be edited directly
    suffix?: string; // Suffix for the input value (units) - only works with the 'number' type
    decimalDigits?: number; // Whether to allow decimal digits and how many of them
    placeholder?: string; // Placeholder attribute for the input
  }>(),
  {
    modelValue: null,
    type: 'number',
    rules: undefined,
    selected: null,
    label: undefined,
    clearable: true,
    restorableTo: null,
    borderColor: undefined,
    active: false,
    disabled: false,
    directInput: false,
    suffix: undefined,
    decimalDigits: undefined,
    placeholder: undefined,
  },
);

const emit = defineEmits<{
  'clear:clicked': []; // the clear icon has been clicked on
  'restore:clicked': []; // the restore icon has been clicked on
  'label:clicked': []; // the label has been clicked on
  'update:selected': [value: Selector];
}>();

const selection = computed<Selector>({
  get: () => (Array.isArray(props.selected) ? props.selected.join(', ') : props.selected),
  set: (newSelection) => emit('update:selected', newSelection), // emit in case of direct input ('directInput' == true)
});

const { errorMessage, value } = useField(props.name, props.rules, {
  initialValue: selection.value,
});

const isEmptySelection = computed(() => {
  const s = selection.value;
  return s === null || s === '' || (Array.isArray(s) && s.length === 0);
});

const clearSelection = () => {
  const emptyValue = props.type === 'string' ? '' : null;
  selection.value = emptyValue;
  emit('clear:clicked');
};

watch(selection, (newSelection) => (value.value = newSelection));
</script>

<style scoped>
.p-button.button-label {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-width: 3px;
}
.p-button.p-button-icon-only.clear-icon {
  width: 1.5rem;
}
.p-button.button-label.active {
  background-color: #007aad;
}
.p-button.button-label.disabled {
  background-color: #66bfe4;
  border-color: #66bfe4;
}
.disabled {
  cursor: initial;
  pointer-events: none;
}
</style>
