<template>
  <div class="p-grid">
    <div class="p-col-6">
      <tm-calendar
        v-model:selected="dateFrom"
        :popup="true"
        :label="'modifications.date from'"
        :clearable="true"
        :show-time="true"
        :disabled="disabled"
        :limits="dateFromLimits"
        :strip-minutes="stripMinutes"
        :placeholder="placeholder"
        data-test="date-from"
      />
    </div>
    <div class="p-col-6">
      <tm-calendar
        v-model:selected="dateTo"
        :popup="true"
        :label="'modifications.date to'"
        :clearable="true"
        :show-time="true"
        :disabled="disabled"
        :limits="dateToLimits"
        :strip-minutes="stripMinutes"
        :placeholder="placeholder"
        data-test="date-to"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    from?: TmDate; // Date from
    to?: TmDate; // Date to
    disabled?: boolean; // Disables editing
    datesLimit?: { minDate?: Date; maxDate?: Date }; // Date limits for both dates as JS Date()
    stripMinutes?: boolean; // ignore minutes in both of the two dates
    placeholder?: string; // placeholder for both dates
  }>(),
  {
    from: null,
    to: null,
    disabled: false,
    datesLimit: () => ({}),
    stripMinutes: false,
    placeholder: '',
  },
);

const emit = defineEmits<{
  'update:from': [date: TmDate];
  'update:to': [date: TmDate];
}>();

const dateFrom = computed({
  get: () => props.from,
  set: (date) => emit('update:from', date),
});

const dateTo = computed({
  get: () => props.to,
  set: (date) => emit('update:to', date),
});

const dateFromLimits = computed(() => {
  const minDate = props.datesLimit.minDate;
  const maxDate = dateTo.value ? new Date(dateTo.value) : undefined;
  return { minDate, maxDate };
});

const dateToLimits = computed(() => {
  const minDate = dateFrom.value ? new Date(dateFrom.value) : undefined;
  const maxDate = props.datesLimit.maxDate;
  return { minDate, maxDate };
});
</script>
