import { defineRule, configure } from 'vee-validate';
import globalRules from '@vee-validate/rules';
import { localize, setLocale } from '@vee-validate/i18n';
import generalEnLocales from '@vee-validate/i18n/dist/locale/en.json';
import generalCsLocales from '@vee-validate/i18n/dist/locale/cs.json';
import customLocales from '@locales/plugins/vee-validate'; // form fields names and custom error messages for specific fields rules
import { merge } from 'lodash-es';

export const configureVeeValidate = (locale: TmLang) => {
  // Globally define all available @vee-validate rules
  Object.keys(globalRules).forEach((rule) => {
    if (typeof globalRules[rule] === 'function') defineRule(rule, globalRules[rule]);
  });
  // Cross field confirmation custom rule (e.g. confirmed:field_name)
  defineRule('confirmed', (value, [target]: any[], ctx) => {
    return value === ctx.form[target];
  });

  configure({
    validateOnInput: true,
    generateMessage: localize({
      en: merge(generalEnLocales, customLocales.en),
      cs: merge(generalCsLocales, customLocales.cs),
    }),
  });

  setLocale(locale);
};
