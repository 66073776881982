<template>
  <div class="tm-calendar" :class="{ labeled: label, clearable: selectedDay && clearable }">
    <div :class="{ input: isPopup }">
      <span v-if="isPopup && label" class="p-button calendar-label" :class="{ 'p-disabled': disabled }">{{
        $t(label)
      }}</span>
      <prime-calendar
        v-model="selectedDay"
        :inline="!isPopup"
        :show-button-bar="showTodayButton && (isPopup || timeslider)"
        selection-mode="single"
        :min-date="limits.minDate"
        :max-date="limits.maxDate"
        :manual-input="false"
        :append-to="isPopup ? 'body' : undefined"
        :show-time="isPopup && showTime"
        :select-other-months="true"
        :touch-u-i="$isMobile()"
        :hide-on-date-time-select="$isMobile()"
        :disabled="disabled || disabledDate"
        :placeholder="placeholder"
        :panel-class="getCalendarClass()"
        @date-select="emitDateAndTime()"
        @clear-click="clearDate()"
      />
      <i
        v-if="isPopup && clearable && selectedDay && !disabled"
        class="p-button p-button-icon-only calendar-icon pi-times pi"
        @click="clearDate()"
      />
    </div>

    <div v-if="timeslider" class="timeslider" :class="{ separated: isPopup }">
      <tm-slider
        v-model:selected="selectedHour"
        :step="timeOptions.step"
        :range="{ min: timeOptions.min, max: timeOptions.max }"
        :disabled="disabled || disabledTime"
        :suffix="'h'"
        :label="isPopup ? $t('widgets.time') : ''"
        :input="isPopup"
        :value-as-time-range="isPopup"
        @slideend="emitDateAndTime()"
        @click="emitDateAndTime()"
      ></tm-slider>
    </div>
  </div>
</template>

<script setup lang="ts">
import PrimeCalendar from 'primevue/calendar';
import useDate from '@composables/useDate';
import { ref, computed, watch } from 'vue';
import useMobile from '@composables/useMobile';

type DateLimits = { minDate?: Date; maxDate?: Date };
type TimeOptions = { min: number; max: number; step: number };

const props = withDefaults(
  defineProps<{
    timeslider?: boolean; // Whether the calendar should display time slider
    limits?: DateLimits; // Date limits (minimum and maximum selectable date as JS Date()) (cannot be passed as tmDate since converting to JS Date here would trigger reactivity for prime-calendar watches/focuses)
    selected?: TmDate; // Pre-selected default date as iso string (tmDate) - can be null if the date is not set
    popup?: boolean; // Whether should calendar display as input and only enable date picking on the popup window
    clearable?: boolean; // Whether should calendar display icon over its input to enable clearing the date (only for popup version)
    label?: string; // Label to display with (before) the input (only for popup version)
    showTime?: boolean; // Whether to display time with the popup calendar
    showTodayButton?: boolean; // Whether to display 'today' button (styled under the calendar over the slider in case of inline calendar)
    disabled?: boolean; // Whether to disable date & time editing
    disabledDate?: boolean; // Whether to disable only date and keep the time slider enabled (probably just a temporary prop, only utilized by DTA to represent model monday)
    disabledTime?: boolean; // Enabled date input but disabled timeslider
    timeOptions?: TimeOptions; // Time slider options
    stripMinutes?: boolean; // ignore minutes whenever they are passed into / displayed by calendar component
    placeholder?: string; // placeholder text for the input
  }>(),
  {
    timeslider: false,
    limits: () => ({}),
    selected: null,
    popup: false,
    clearable: false,
    label: 'scenarios.date',
    showTime: false,
    showTodayButton: true,
    disabled: false,
    disabledDate: false,
    disabledTime: false,
    timeOptions: () => ({ min: 0, max: 23, step: 1 }),
    stripMinutes: false,
    placeholder: '',
  },
);

const emit = defineEmits<{
  'update:selected': [selected: TmDate];
}>();

const { toJsDate, toTmDate, getTmDateHoursNumber, getHoursFromHoursNumber, getMinutesFromHoursNumber } = useDate();
const isMobile = useMobile();
const selectedDay = ref<Date | undefined>(undefined);
const selectedHour = ref<number | null>(null);
const isPopup = computed<boolean>(() => props.popup || isMobile());

watch(
  () => props.selected,
  (selection) => {
    selectedDay.value = selection ? toJsDate(selection, { stripMins: props.stripMinutes }) || undefined : undefined;
    selectedHour.value = selection ? getTmDateHoursNumber(selection, { stripMins: props.stripMinutes }) : null;
  },
  { immediate: true },
);

const emitDateAndTime = () => {
  const day = selectedDay.value;
  const hour = selectedHour.value;
  if (day) day.setSeconds(0, 0);
  if (props.stripMinutes && day) day.setMinutes(0, 0, 0);
  if (props.timeslider && day && hour !== null) {
    // add the selected hour to the selected day when using timeslider
    day.setHours(getHoursFromHoursNumber(hour), getMinutesFromHoursNumber(hour));
  }
  emit('update:selected', day ? toTmDate(day) : null);
};

const clearDate = () => {
  selectedDay.value = undefined;
  emitDateAndTime();
};

const getCalendarClass = () => {
  const classList = [];
  if (props.stripMinutes) classList.push('minuteless-calendar');
  if (isPopup.value && !props.showTime) classList.push('popup-timeless-calendar');
  if (props.showTime && props.showTodayButton) classList.push('full-bar-calendar');
  return classList.join(' ');
};
</script>

<style scoped>
.p-calendar {
  width: 100%;
}
.timeslider {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-top: 0px;
}
.timeslider :deep(.tm-slider) {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.timeslider.separated {
  margin-top: 1rem;
  background-color: initial;
  border: none;
}
:deep(thead) {
  display: none;
}
:deep(.p-datepicker) {
  background-color: #f8f9fa;
  width: 100%;
}
:deep(.p-datepicker-header) {
  background-color: #f8f9fa;
}
:deep(.p-datepicker table td) {
  padding-top: 0px;
  padding-bottom: 0px;
}
:deep(.p-datepicker-group-container) {
  width: 100%;
}
:deep(.p-slider-range) {
  display: none;
}
.tm-calendar .p-button {
  overflow: visible;
}
.tm-calendar .input {
  width: auto;
  display: flex;
}
.tm-calendar.labeled :deep(.p-calendar .p-inputtext) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.tm-calendar.clearable :deep(.p-calendar .p-inputtext) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.calendar-label {
  cursor: default;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.calendar-icon {
  cursor: pointer;
  padding-top: 0.7rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.calendar-icon:hover {
  background-color: #007aad;
}
/* global calendar styling - needed for the popup datepicker which is on the root level */
:global(.minuteless-calendar .p-timepicker) {
  position: relative;
  height: 6rem;
}
:global(.minuteless-calendar .p-timepicker .p-hour-picker button.p-link) {
  position: absolute;
  left: 46%;
}
:global(.minuteless-calendar .p-timepicker .p-hour-picker button.p-link:first-of-type) {
  top: 0;
}
:global(.minuteless-calendar .p-timepicker .p-hour-picker button.p-link:last-of-type) {
  bottom: 0;
}
:global(.minuteless-calendar .p-timepicker .p-minute-picker button.p-link) {
  display: none;
}
:global(.minuteless-calendar .p-timepicker .p-minute-picker span),
:global(.minuteless-calendar .p-timepicker .p-hour-picker span) {
  width: 1.3rem;
}
:global(.minuteless-calendar .p-timepicker .p-minute-picker span) {
  position: relative;
  visibility: hidden;
}
:global(.minuteless-calendar .p-timepicker .p-minute-picker span::before) {
  content: '00';
  visibility: visible;
}
:global(.p-datepicker .p-datepicker-buttonbar) {
  position: absolute;
  border-top: none;
  width: 96%;
  flex-direction: row-reverse;
  bottom: -4rem;
  pointer-events: none;
}
:global(.p-datepicker-buttonbar .p-button:nth-child(1)) {
  pointer-events: initial; /* enable today button interaction */
}
:global(.p-datepicker-buttonbar .p-button:nth-child(2)) {
  display: none; /* hide clear button */
}
:global(.full-bar-calendar .p-datepicker-buttonbar) {
  bottom: 3.7rem;
}
:global(.minuteless-calendar.full-bar-calendar .p-datepicker-buttonbar) {
  bottom: 2.7rem;
}
:global(.popup-timeless-calendar .p-datepicker-buttonbar) {
  border-top: 1px solid #dee2e6;
  position: initial;
  width: auto;
  padding: 0.3rem 0 0 0;
}
</style>
