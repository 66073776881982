import type { localize } from '@vee-validate/i18n';

type VeeValidateLocales = Record<TmLang, Parameters<typeof localize>[1]>;

const locales: VeeValidateLocales = {
  cs: {
    names: {
      name: 'název',
      description: 'popis',
      email: 'e-mail',
      password: 'heslo',
      username: 'uživatelské jméno',
      email_username: 'e-mail / uživatelské jméno',
      links: 'úseky',
      source: 'start',
      target: 'cíl',
      node: 'uzel',
      nodes: 'uzly',
      generator: 'generátor',
      password2: 'heslo pro kontrolu',
      confirm_password: 'heslo',
      old_password: 'stávající heslo',
      new_password: 'nové heslo',
      calibrated_matrix_name: 'název nově kalibrované matice',
      matrix_ref_id: 'referenční ID',
      model_reference_name: 'odkaz na model',
    },
    fields: {
      username: {
        required: 'Uživatelské jméno je povinné. Lze jej po vytvoření uživatele změnit.',
      },
    },
    messages: {
      max_value: 'Pole {field} musí být 0:{max}, nebo menší', // typo fix of the imported general message (from @vee-validate/i18n/dist/locale/cs.json)
    },
  },

  en: {
    names: {
      name: 'name',
      description: 'description',
      email: 'e-mail',
      password: 'password',
      username: 'username',
      email_username: 'e-mail / username',
      links: 'links',
      source: 'source',
      target: 'target',
      node: 'node',
      nodes: 'nodes',
      generator: 'generator',
      password2: 'password again',
      confirm_password: 'password',
      old_password: 'current password',
      new_password: 'new password',
      calibrated_matrix_name: 'calibration matrix name',
      matrix_ref_id: 'reference ID',
      model_reference_name: 'reference model name',
    },
    fields: {
      username: {
        required: 'The username field must be filled. It can be changed after the user is created.',
      },
    },
  },
};

export default locales;
