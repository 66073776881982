import useMainApiRequest from '@composables/useMainApiRequest';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useDialog from '@composables/useDialog';

export default function useEvents(
  itemIds: TmItemIds,
  {
    cacheEvent,
    invalidateEvent,
    updateCachedEvent,
    invalidateModification,
    invalidateNodeTransit,
  }: {
    cacheEvent: (ev: TmEvent | TmEvent[], ids?: { scenarioId: number; eventId?: number }) => void;
    invalidateEvent: (ids: { scenarioId: number; eventId?: number }) => void;
    updateCachedEvent: (key: keyof TmEvent, value: any, ids?: { scenarioId: number; eventId?: number }) => void;
    invalidateModification: (ids: { scenarioId: number; eventId?: number; modificationId?: number }) => void;
    invalidateNodeTransit: (scId?: number, nodeId?: string) => void;
  },
) {
  const { t } = useI18n();
  const router = useRouter();
  const { makeRequest, checkIds } = useMainApiRequest();
  const store = useStore();
  const dialog = useDialog();
  const shouldLoadWithChanges = (id: number): boolean => store.getters['scenarios/isUsingChangedScenario'](id);

  async function fetchEvents() {
    const [scenarioId] = checkIds([itemIds.scenarioId]);

    await makeRequest({
      url: `scenarios/${scenarioId}/events`,
      method: 'get',
      ...(shouldLoadWithChanges(scenarioId) ? { params: { useChanged: true } } : {}),
      message: {
        error: {
          404: { summary: t('events.no events found'), severity: 'info' },
          default: t('events.error while fetching events'),
        },
      },
      onSuccess: (result: { events: TmEvent[] }) => cacheEvent(result.events, { scenarioId }),
    });
  }

  async function fetchEvent(id?: number) {
    const [scenarioId, eventId] = checkIds([itemIds.scenarioId, id]);

    await makeRequest({
      url: `scenarios/${scenarioId}/events/${eventId}`,
      method: 'get',
      ...(shouldLoadWithChanges(scenarioId) ? { params: { useChanged: true } } : {}),
      message: {
        error: t('events.error while fetching event'),
      },
      onSuccess: (result: { event: TmEvent }) => cacheEvent(result.event, { scenarioId, eventId }),
    });
  }

  async function createEvent(scEvent: TmEvent, { leaveAfter = true } = {}) {
    const [scenarioId] = checkIds([itemIds.scenarioId]);

    await makeRequest({
      url: `scenarios/${scenarioId}/events`,
      method: 'post',
      data: {
        name: scEvent.name,
        description: scEvent.description,
        note: scEvent.note,
        externalId: scEvent.externalId,
        type: scEvent.type,
        dateFrom: scEvent.dateFrom,
        dateTo: scEvent.dateTo,
      },
      message: {
        success: t('events.event created'),
        error: t('events.failed to create event'),
      },
      onSuccess: async (result: { event: TmEvent; editSession: TmEditSession }) => {
        const resultedEvent = result.event;
        cacheEvent(resultedEvent, { scenarioId, eventId: resultedEvent.id });

        if (leaveAfter) router.push({ name: 'scenarios.events.modifications', params: { evId: resultedEvent.id } });
      },
    });
  }

  async function updateEvent(scEvent: TmEvent, { leaveAfter = false, updateDependencies = false } = {}) {
    const [scenarioId, eventId] = checkIds([itemIds.scenarioId, scEvent.id]);
    const parseDate = (d?: TmDate) => (typeof d === 'undefined' ? undefined : d);

    await makeRequest({
      url: `scenarios/${scenarioId}/events/${eventId}`,
      method: 'patch',
      ...(updateDependencies && { params: { updateDependencies: true } }),
      data: {
        name: scEvent.name,
        description: scEvent.description,
        note: scEvent.note,
        externalId: scEvent.externalId,
        included: scEvent.included,
        type: scEvent.type,
        dateFrom: parseDate(scEvent.dateFrom),
        dateTo: parseDate(scEvent.dateTo),
      },
      message: {
        success: t('events.event updated'),
        error: { default: t('events.failed to update event'), DEPENDENCIES_ERROR: null },
      },
      onSuccess: (result: { event: TmEvent; editSession: TmEditSession; updatedEvents: number[] }) => {
        const resultedEvent = result.event;
        if (scEvent.included !== undefined) {
          result.updatedEvents.forEach((evId) => {
            updateCachedEvent('included', scEvent.included, { scenarioId, eventId: evId });
          });
        } else {
          cacheEvent(resultedEvent, { scenarioId, eventId: resultedEvent.id });
        }

        if (leaveAfter) router.push({ name: 'scenarios.events', params: { id: scenarioId } });
      },
      onFailure: (error: TmApiResponse['data']) => {
        if (error.code === 'DEPENDENCIES_ERROR') {
          dialog.show({
            type: 'dependencies',
            data: { included: scEvent.included, dependencies: error.message?.split('modifications:')[1] },
            callback: {
              onConfirm: async () => {
                await updateEvent(scEvent, { leaveAfter, updateDependencies: true });
                dialog.close();
              },
            },
          });
        }
      },
    });
  }

  async function deleteEvent(id?: number, { deleteDependencies = false } = {}) {
    const [scenarioId, eventId] = checkIds([itemIds.scenarioId, id]);

    await makeRequest({
      url: `scenarios/${scenarioId}/events/${eventId}`,
      method: 'delete',
      ...(deleteDependencies && { params: { deleteDependencies: true } }),
      message: {
        success: t('events.event deleted'),
        error: { default: t('events.failed to delete event'), DEPENDENCIES_ERROR: null },
      },
      onSuccess: (result: {
        event: TmEvent;
        editSession: TmEditSession;
        deletedModifications: TmDeletedModification[];
      }) => {
        // invalidate deleted event (with all its mods)
        invalidateEvent({ scenarioId, eventId });

        // invalidate every other deleted modification & update related resources
        result.deletedModifications.forEach(({ evId, modId, mode, type, evUpdatedAt, evDateFrom, evDateTo }) => {
          invalidateModification({ scenarioId, eventId: evId, modificationId: modId });
          if (mode === 'existing' && type === 'node') invalidateNodeTransit(scenarioId);
          if (!evUpdatedAt) return;
          updateCachedEvent('updatedAt', evUpdatedAt, { scenarioId, eventId: evId });
          updateCachedEvent('dateFrom', evDateFrom, { scenarioId, eventId: evId });
          updateCachedEvent('dateTo', evDateTo, { scenarioId, eventId: evId });
        });

        const activeEventId: number | null = store.getters['map/getActiveItemId']({ itemLevel: 'event' });
        if (eventId == activeEventId) store.dispatch('map/activateItem', { scenarioId });
      },
      onFailure: (error: TmApiResponse['data']) => {
        if (error.code === 'DEPENDENCIES_ERROR') {
          dialog.show({
            type: 'dependencies',
            data: { dependencies: error.message?.split('modifications:')[1] },
            callback: {
              onConfirm: async () => {
                await deleteEvent(eventId, { deleteDependencies: true });
                dialog.close();
              },
            },
          });
        }
      },
    });
  }

  async function copyEvent(id?: number) {
    const [scenarioId, eventId] = checkIds([itemIds.scenarioId, id]);

    await makeRequest({
      url: `scenarios/${scenarioId}/events/${eventId}/copy`,
      method: 'post',
      message: {
        success: t('events.event copied'),
        error: t('events.failed to copy event'),
      },
      onSuccess: (result: { event: TmEvent; editSession: TmEditSession }) => {
        const resultedEvent = result.event;
        cacheEvent(resultedEvent, { scenarioId, eventId: resultedEvent.id });

        // activate copied item
        store.dispatch('map/activateItem', {
          scenarioId,
          eventId: resultedEvent.id,
          date: resultedEvent.dateFrom,
        });
      },
    });
  }

  async function fetchImportableEvents({
    onSuccess = () => {},
  }: { onSuccess?: (arg: any) => Promise<void> | void } = {}) {
    const [scenarioId] = checkIds([itemIds.scenarioId]);

    await makeRequest({
      url: `scenarios/${scenarioId}/importable-events`,
      method: 'get',
      message: {
        error: {
          404: { summary: t('events.no events found'), severity: 'info' },
          default: t('events.error while fetching events'),
        },
      },
      onSuccess: (result: { events: TmEvent[] }) => {
        // not cached for now
        // TODO: cache separately by scenarioId and userId + invalidate after any user access change and any event change..
        onSuccess(result.events);
      },
    });
  }

  async function importEvents(events: { eventId?: number; scenarioId?: number }[], { leaveAfter = true } = {}) {
    const [scenarioId] = checkIds([itemIds.scenarioId]);

    await makeRequest({
      url: `scenarios/${scenarioId}/import-events`,
      method: 'post',
      data: { events },
      message: {
        success: t('events.event imported'),
        error: t('events.failed to import event'),
      },
      onSuccess: (result: { events: TmEvent[]; editSession: TmEditSession }) => {
        const resultedEvents = result.events;
        resultedEvents.forEach((ev) => cacheEvent(ev, { scenarioId, eventId: ev.id }));

        if (leaveAfter) router.push({ name: 'scenarios.events', params: { id: scenarioId } });
      },
    });
  }

  return {
    fetchEvents,
    fetchEvent,
    createEvent,
    updateEvent,
    deleteEvent,
    copyEvent,
    fetchImportableEvents,
    importEvents,
  };
}
