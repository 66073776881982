<template>
  <div class="p-mt-3 p-mb-3">
    <div v-if="ready">
      <div v-if="showSearch">
        <div class="p-inputgroup p-mb-3">
          <prime-button icon="ri ri-search-line" />
          <prime-input-text v-model="searchPhrase" name="search" :placeholder="$t('scenarios.search')" />
        </div>
      </div>

      <div class="select-wrapper">
        <prime-multi-select
          :model-value="flatSelectedFilters"
          :options="availableFilteringOptions"
          :option-label="(option) => getLocalizedLabel(option)"
          :option-group-label="(group) => getLocalizedLabel(group)"
          option-group-children="items"
          :placeholder="$t('scenarios.filters')"
          :show-toggle-all="false"
          data-test="grouped-filters"
          @update:model-value="updateSelectedFilters"
        >
          <template #value>
            <i class="ri-filter-line filter-icon" />
            {{ $t('scenarios.filters') }}
            <prime-badge v-if="flatSelectedFilters.length" :value="flatSelectedFilters.length" />
          </template>
        </prime-multi-select>

        <prime-dropdown
          v-model="selectedSorting"
          :options="availableSortingOptions"
          :placeholder="$t('scenarios.sorting')"
          :option-label="(option) => getLocalizedLabel(option)"
          data-test="sorting"
        >
          <template #option="slotProps">
            <div>{{ getLocalizedLabel(slotProps.option) }}</div>
          </template>
        </prime-dropdown>
      </div>
    </div>
    <div v-else>
      <div class="p-d-flex p-ai-end p-mb-3">
        <prime-skeleton height="2rem" width="30%" class="p-mr-2" />
        <prime-skeleton height="2rem" width="30%" class="p-mr-2" />
        <prime-skeleton height="2rem" width="30%" class="p-mr-2" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T extends Object">
import { computed, ref, toRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import PrimeMultiSelect from 'primevue/multiselect';
import PrimeDropdown from 'primevue/dropdown';
import PrimeBadge from 'primevue/badge';
import PrimeInputText from 'primevue/inputtext';
import PrimeButton from 'primevue/button';
import useFilters, { type Filters, type Filter, type Sorting } from '@composables/useFilters';

const props = withDefaults(
  defineProps<{
    modelValue: T[];
    filteringOptions: Filters | undefined;
    sortingOptions: Sorting[] | undefined;
    showSearch?: boolean;
    items: T[];
    cacheKey: string;
    ready: boolean; // Whether the content is ready to be displayed (if false - it will display temporal skeleton panels)
    groupLabels?: Record<string, string>; // Optional custom labels for the filter groups as Records (groupKey: groupLabel)
  }>(),
  {
    showSearch: true,
    groupLabels: () => ({
      sharing: 'scenarios.sharing',
      date: 'scenarios.date',
      inclusion: 'scenarios.inclusion',
      type: 'scenarios.type',
      owner: 'scenarios.owner',
    }),
  },
);

const { t } = useI18n();
const emit = defineEmits<{ 'update:modelValue': [filteredItems: T[]] }>();

const searchPhrase = ref<string>('');
const selectedFilters = ref<Filters>(getDefaultSelectedFilters());
const selectedSorting = ref<Sorting>(getDefaultSelectedSorting());

const { filteredItems, ignoredFilterGroups, ignoredSortingNames } = useFilters<T>({
  items: toRef(props, 'items'),
  cacheKey: props.cacheKey,
  selectedFilters,
  selectedSorting,
  searchPhrase,
});

const availableFilteringOptions = computed(() => {
  const nestedOptions: { label: string; items: Filter[] }[] = []; // nested format expected by the prime multi select
  if (!props.filteringOptions) return nestedOptions;
  return Object.entries(props.filteringOptions).reduce((acc, [key, value]) => {
    if (ignoredFilterGroups.includes(key)) return acc;
    acc.push({ label: props.groupLabels[key], items: value });
    return acc;
  }, nestedOptions);
});

const availableSortingOptions = computed(() => {
  if (!props.sortingOptions) return [];
  return props.sortingOptions.filter((o) => !ignoredSortingNames.includes(o.name));
});

const flatSelectedFilters = computed(() => {
  if (!selectedFilters.value) return [];
  // return fat array of selected filtering options
  return Object.values(selectedFilters.value).flatMap((arr) => arr.concat());
});

function getLocalizedLabel(option: string | { label: string } | { optionGroup: { label: string } }) {
  if (typeof option === 'string') return option;
  if ('label' in option && option.label) return t(option.label);
  if ('optionGroup' in option && option.optionGroup.label) return t(option.optionGroup.label);
  return 'n/a';
}

function getDefaultSelectedFilters(): Filters {
  if (!props.filteringOptions) return {};
  return Object.entries(props.filteringOptions).reduce((acc: Filters, [key, value]) => {
    acc[key] = value.filter((item) => item.default);
    return acc;
  }, {});
}

function getDefaultSelectedSorting(): Sorting {
  const defaultSorting = props.sortingOptions?.find((o) => o.default);
  return defaultSorting || { name: 'id' };
}

function updateSelectedFilters(newSelection: Filter[]) {
  const selectedFilterNames = newSelection?.map((o) => o.name) || [];
  if (!props.filteringOptions) return;
  const groupedSelection = Object.entries(props.filteringOptions).reduce((acc: Filters, [key, value]) => {
    acc[key] = value.filter((item) => selectedFilterNames.includes(item.name));
    return acc;
  }, {});
  selectedFilters.value = groupedSelection;
}

watch(
  filteredItems,
  (items) => {
    emit('update:modelValue', items);
  },
  { immediate: true },
);
</script>

<style scoped>
.select-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.select-wrapper > div {
  padding: 0;
  margin: 0;
  width: 49%;
}

:deep(.p-multiselect-token),
:deep(.p-multiselect-token-icon) {
  font-size: 0.9rem;
}

:deep(.p-multiselect),
:deep(.p-dropdown) {
  overflow: hidden;
}

:deep(.p-multiselect-label),
:deep(.p-dropdown-label.p-inputtext) {
  padding: 0.2rem 0rem 0.2rem 0.5rem !important;
}

:deep(.p-multiselect-token) {
  margin: 0.2rem 0 0 0 !important;
  display: flex;
  justify-content: space-between;
}

:deep(.p-multiselect-token-label) {
  max-width: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

:deep(.p-multiselect-token-icon) {
  margin-left: 0.1rem !important;
}

:deep(.p-multiselect-label .filter-icon),
:deep(.p-dropdown-label.p-inputtext:before) {
  padding-right: 0.3rem;
  vertical-align: -5%;
}

:deep(.p-dropdown-label.p-inputtext:before) {
  font-family: 'remixicon' !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  content: '\f160';
  vertical-align: -10%;
}

:deep(.p-badge) {
  height: 1rem;
  width: 1rem;
  min-width: 1rem;
  vertical-align: 10%;
  line-height: 1rem;
  margin-left: 0.3rem;
}
</style>
