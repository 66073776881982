<template>
  <div
    class="p-text-center tm-button"
    :class="{ disabled: disabled, 'tm-fullwidth': ['confirm', 'cancel', 'toggle'].includes(type) }"
  >
    <prime-button
      v-tooltip.bottom="!$isMobile() && tooltip"
      type="button"
      :disabled="disabled"
      :icon="buttonIcon"
      :class="buttonClass"
      :label="label"
      :data-type="type"
    >
      <slot />
    </prime-button>
    <span v-if="$isMobile() && tooltip" class="p-d-block p-mt-2">{{ tooltip }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import PrimeButton from 'primevue/button';

export type ButtonColor = 'red' | 'green' | 'yellow' | 'blue';
type ButtonType =
  | 'basic'
  | 'cancel'
  | 'confirm'
  | 'back'
  | 'navigation'
  | 'create'
  | 'toggle'
  | 'action'
  | 'link'
  | 'zoom'
  | 'widget';

const props = withDefaults(
  defineProps<{
    label?: string; // Label of the actual button
    type?: ButtonType; // Type of the button - represents some styling
    icon?: string; // What icon to prepend (prime/remix icon string, e.g. pi-home/ri-user-line)
    disabled?: boolean; // Disabled state of the button
    borderColor?: ButtonColor; // Color of the button border
    tooltip?: string; // Button tooltip
  }>(),
  {
    label: undefined,
    type: 'basic',
    icon: undefined,
    disabled: false,
    borderColor: undefined,
    tooltip: undefined,
  },
);

// TS_TODO: slot definition is not very useful here without any props/type definitions, but it is kept here as a showcase
defineSlots<{
  default?: (props: {}) => any; // vue 3.3. -> Slot function return type is currently ignored and can be any, but we may leverage it for slot content checking in the future.
}>();

const buttonClass = computed(() => {
  const typeClass = {
    'p-button-rounded': ['navigation', 'back', 'create', 'action', 'widget'].includes(props.type),
    'p-button-secondary': ['toggle'].includes(props.type),
    'tm-navigation': ['navigation', 'toggle'].includes(props.type),
    'p-button-icon-only': ['navigation'].includes(props.type),
    'tm-fullwidth': ['confirm', 'cancel', 'toggle'].includes(props.type),
    'tm-underlined': props.type === 'link' && !props.icon,
    [`tm-${props.type}`]: true,
  };
  const borderClass = props.borderColor ? 'border-color-' + props.borderColor : '';

  return [typeClass, borderClass];
});

const buttonIcon = computed(() => {
  if (!props.icon) return '';
  const iconType = props.icon.split('-')[0]; // separate icon prefix class e.g. pi/ri
  return `${iconType} ${props.icon}`;
});
</script>

<style scoped>
.tm-button {
  height: fit-content;
  display: inline-block;
}
.tm-button.tm-fullwidth,
.p-button.tm-fullwidth {
  width: 100%;
}
.tm-button.active .p-button.tm-navigation,
.p-button.tm-navigation:hover {
  box-shadow: 0 0 0 0.2em #bbdefb !important;
  background-color: #0094d2;
  color: white;
}
.tm-button.active .p-button.tm-navigation.active:hover {
  background-color: #007aad;
}
.p-button.p-button-secondary {
  background-color: #cccccc;
  border-color: #cccccc;
}
.p-button.tm-navigation {
  text-decoration: none;
  background-color: inherit;
  border: #495057;
  color: #495057;
  box-shadow: 0 0 0 0.1em #495057;
}
.p-button:focus.tm-navigation {
  box-shadow: 0 0 0 0.1em #495057;
}
.p-button.tm-back {
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  margin-right: 1rem;
}
.p-button.tm-link {
  background: none;
  border: none;
  padding: 0;
  color: #0094d2;
}
.p-button.tm-link:hover {
  color: #007aad;
}
.tm-button.tm-reload button {
  background-color: red !important;
}
.p-button-icon-only {
  width: 2.357rem !important;
  padding: 0.5rem 0;
}
.p-button.tm-zoom {
  border: 1px solid #ced4da;
  background: #ffffff;
  color: #495057;
}
.p-button.tm-zoom:hover {
  background: #e9ecef !important;
  color: #495057 !important;
}
.p-button.tm-widget {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  color: #495057;
}
.p-button.tm-widget :deep(.ri) {
  font-size: 20px;
}
.p-button.tm-widget:hover,
.tm-button.active .p-button.tm-widget {
  color: #0094d2;
}
.tm-button.disabled {
  cursor: initial;
  pointer-events: none;
}
.tm-underlined {
  text-decoration: underline;
}
.p-button :deep(.ri-play-fill:before) {
  padding: 0 0 0 0.1rem;
}
</style>
