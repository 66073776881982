import { ref, computed, markRaw, reactive } from 'vue';
import { useStore } from 'vuex';
import useForeignApiRequest from '@composables/useForeignApiRequest';
import { transformGeojson } from '@utils/geojson';
import {
  LID_REAL,
  LID_STA_LINKS,
  LID_STA_NODES,
  LID_STA_GENERATORS,
  LID_DTA_LINKS,
  LID_DTA_NODES,
  LID_DTA_GENERATORS,
} from '@keys/index';
import { today, formatAsApiDate } from '@utils/tm-date';
import useModels from '@composables/useModels';

const SOURCE_ID_KEYS = {
  [LID_STA_LINKS]: 'edge_id',
  [LID_STA_NODES]: 'node_id',
  [LID_STA_GENERATORS]: 'zone_id',
  [LID_DTA_LINKS]: 'edge_id',
  [LID_DTA_NODES]: 'node_id',
  [LID_DTA_GENERATORS]: 'zone_id',
};
const SOURCE_PARAMS = {
  [LID_REAL]: () => ({
    start_time: '2017-01-01T00:00:00', // Hardcoded, roughly first available api date
    end_time: formatAsApiDate(today()),
    group_by: 'True',
    geometry_type: 'LineString',
    return_geometry: 'True',
  }),
};

const dtaCoordsTransformer = (data) => transformGeojson(data, { sourceProj: 'EPSG:4326', targetProj: 'EPSG:3857' });
const DATA_PARSER = {
  [LID_DTA_LINKS]: dtaCoordsTransformer,
  [LID_DTA_NODES]: dtaCoordsTransformer,
  [LID_DTA_GENERATORS]: dtaCoordsTransformer,
};

const sourceCache = ref({});
const extraFeaturesStore = ref({});
const sourceFeaturesCache = {};
const loadingCache = ref({});

export default function useLayerSourceData(layerKey, { autoFetch = true, clearOldData = false } = {}) {
  if (!layerKey) throw Error('SourceKey not provided');
  const store = useStore();
  const { fetchModels, activeModel } = useModels();

  if (sourceCache.value[layerKey] === undefined) {
    sourceFeaturesCache[layerKey] = new Map();
    sourceCache.value[layerKey] = null;
    extraFeaturesStore.value[layerKey] = [];
    loadingCache.value[layerKey] = false;
  }
  const { makeRequest } = useForeignApiRequest();

  const sourceData = computed(() => sourceCache.value[layerKey]);
  const isLoaded = computed(() => sourceCache.value[layerKey]?.features?.length > 0);
  const isLoading = computed(() => loadingCache.value[layerKey]);

  async function fetchSourceData() {
    if (isLoading.value) return loadingCache.value[layerKey];
    if (isLoaded.value) return sourceCache.value[layerKey];

    const fetchAction = async () =>
      makeRequest({
        url: await _getSourceEndpoint(layerKey),
        method: 'get',
        ...(SOURCE_PARAMS[layerKey] && { params: SOURCE_PARAMS[layerKey]() }),
        message: { error: `FAILED TO LOAD MAP SOURCE DATA (${layerKey})` },
        timeout: 1000 * 60, // 1 minute
        onSuccess: (result) => {
          sourceCache.value[layerKey] = markRaw(DATA_PARSER[layerKey] ? DATA_PARSER[layerKey](result) : result);
          store.dispatch('map/addSourceLoaded', { sourceKey: layerKey, status: true });
        },
        onFailure: (err) => {
          console.log(err);
          store.dispatch('map/addBrokenMapMode', { layerKey });
          store.dispatch('map/addSourceLoaded', { sourceKey: layerKey, status: false });
        },
      });

    // re-use unresolved promise
    return (loadingCache.value[layerKey] = fetchAction().then(
      () => delete loadingCache.value[layerKey], // delete resolved promise
    ));
  }

  const extraFeaturesData = computed(() => extraFeaturesStore.value[layerKey]);
  const setExtraFeaturesData = (features) => (extraFeaturesStore.value[layerKey] = markRaw(features));

  const features = sourceFeaturesCache[layerKey];
  function getFeatureData(featureId) {
    if (!isLoaded.value) throw Error(`Source data for ${layerKey} are not available`);
    const isExtraFeature = typeof featureId === 'string';
    return isExtraFeature ? _getExtraFeatureData(featureId) : _getSourceFeatureData(featureId);
  }

  function _getExtraFeatureData(featureId) {
    const feature = extraFeaturesData.value.find(
      (feature) => feature.properties[SOURCE_ID_KEYS[layerKey] || 'id'] === featureId,
    );
    return feature;
  }

  function _getSourceFeatureData(featureId) {
    if (features.has(featureId)) return features.get(featureId);
    const feature = sourceData.value.features.find(
      (feature) => feature.properties[SOURCE_ID_KEYS[layerKey] || 'id'] === featureId,
    );
    if (feature) features.set(featureId, feature);
    return feature;
  }

  const _getSourceEndpoint = async (layerKey) => {
    if (LID_REAL == layerKey) return import.meta.env.VITE_API_TRAFFIC_REAL;
    await fetchModels(); // ensure that models are fetched, so the endpoints are ready
    const { name, endPoint } = activeModel.value;
    if (!name || !endPoint) throw Error(`No active model set yet`);
    if ([LID_STA_LINKS, LID_DTA_LINKS].includes(layerKey)) return `${endPoint}/edges/${name}`;
    if ([LID_STA_NODES, LID_DTA_NODES].includes(layerKey)) return `${endPoint}/nodes/${name}`;
    if ([LID_STA_GENERATORS, LID_DTA_GENERATORS].includes(layerKey)) return `${endPoint}/zones/${name}`;
    throw Error(`LayerKey ${layerKey} is not supported`);
  };

  function clearSourceData() {
    sourceFeaturesCache[layerKey] = new Map();
    loadingCache.value[layerKey] = false;
    sourceCache.value[layerKey] = null;
  }

  if (clearOldData) clearSourceData();
  if (autoFetch && !isLoading.value) fetchSourceData();

  return {
    fetchSourceData,
    getFeatureData,
    clearSourceData,
    setExtraFeaturesData,
    sourceData,
    extraFeaturesData,
    state: reactive({
      layerKey,
      isLoading,
      isLoaded,
    }),
  };
}

const nodeNeighborLinksCache = ref({
  [LID_STA_LINKS]: new Map(),
  [LID_DTA_LINKS]: new Map(),
});
export function getNodeNeighboringLinksData(nodeId, isDta = false) {
  const layerKey = isDta ? LID_DTA_LINKS : LID_STA_LINKS;
  if (!sourceCache.value[layerKey]) throw Error(`Source data for modelLinks are not available`);
  const propNamesProfile = isDta ? 'DTA' : 'STA';
  return [
    ..._getNodeNeighboringSourceLinks(nodeId, layerKey, propNamesProfile),
    ..._getNodeNeighboringExtraLinks(nodeId, layerKey, propNamesProfile),
  ];
}

function _getNodeNeighboringSourceLinks(nodeId, layerKey, profile) {
  if (nodeNeighborLinksCache.value[layerKey].has(nodeId)) return nodeNeighborLinksCache.value[layerKey].get(nodeId);
  const features = _extractNeighboringLinks(sourceCache.value[layerKey].features, nodeId, profile);
  if (features) nodeNeighborLinksCache.value[layerKey].set(nodeId, features);
  return features;
}

function _getNodeNeighboringExtraLinks(nodeId, layerKey, profile) {
  return _extractNeighboringLinks(extraFeaturesStore.value[layerKey], nodeId, profile);
}

// TODO pull this to app wide config?
const propNames = {
  STA: {
    source: 'source',
    target: 'target',
  },
  DTA: {
    source: 'from_node_id',
    target: 'to_node_id',
  },
};

function _extractNeighboringLinks(dataSource, nodeId, profile) {
  return dataSource.filter((feature) => {
    const source = feature.properties[propNames[profile].source];
    const target = feature.properties[propNames[profile].target];
    return source === nodeId || target === nodeId;
  });
}

/*
    opt {} outKey: layerKey
*/
export function useLayerSourceDataGroup(groupOpt) {
  const group = Object.entries(groupOpt).reduce((out, [outKey, layerKey]) => {
    out[outKey] = useLayerSourceData(layerKey, { autoFetch: false });
    return out;
  }, {});

  const groupList = Object.values(group);
  const isLoaded = computed(() => isLayerSourceGroupLoaded(groupList));
  const load = async () => loadLayerSourceGroup(groupList);

  const clearGroup = () => {
    const dataType = Object.values(group);
    dataType.forEach((dt) => dt.clearSourceData());
  };

  return { load, isLoaded, clearGroup, ...group };
}

export function isLayerSourceGroupLoaded(lsGroup) {
  return lsGroup.every((item) => item.state.isLoaded === true);
}

export async function loadLayerSourceGroup(lsGroup) {
  if (lsGroup.length === 0 || isLayerSourceGroupLoaded(lsGroup)) return true;
  await Promise.all(
    lsGroup.map(async (lsItem) => {
      await lsItem.fetchSourceData();
    }),
  );
}
