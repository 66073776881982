import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useFullScenario from '@composables/useFullScenario';
import useDialog from '@composables/useDialog';
import useEditMode from '@composables/useEditMode';
import { boundingExtent } from 'ol/extent';
import { useMainMap } from '@components/map/map';
import { useLayerSourceDataGroup, loadLayerSourceGroup } from '@composables/useLayerSourceData';
import { parseRouteParamAsNumber } from '@utils/route-param';
import { MODEL_STA_LIDS, MODEL_DTA_LIDS } from '@keys/index';

const newModCoordKey = { link: 'coordinates', node: 'node', generator: 'generator', matrix: 'matrix' };
const featureKeyDict = { link: 'linkId', node: 'node', generator: 'generator', matrix: 'matrix' };

export default function useMapItems() {
  const { activeScenario, fetchScenario, getModifications } = useFullScenario();
  const store = useStore();
  const router = useRouter();
  const dialog = useDialog();
  const { enterEditSession } = useEditMode();
  const { view, olObject } = useMainMap();
  const layerDataSTA = useLayerSourceDataGroup(MODEL_STA_LIDS);
  const layerDataDTA = useLayerSourceDataGroup(MODEL_DTA_LIDS);

  const layerData = computed(() => {
    const isDTA = store.getters['scenarios/isDtaModelTypeActive'];
    return isDTA ? layerDataDTA : layerDataSTA;
  });

  const activateAndFitMapItem = async ({
    scenarioId,
    eventId,
    modificationId,
    date,
  }: TmItemIds & { date?: TmDate } = {}) => {
    await store.dispatch('map/activateItem', { scenarioId, eventId, modificationId, date });
    await adjustMapViewForMapItem({ scenarioId, eventId, modificationId, forced: true });
  };

  const adjustMapViewForMapItem = async ({
    scenarioId,
    eventId,
    modificationId,
    forced = false,
  }: TmItemIds & { forced?: boolean } = {}) => {
    const shouldBeAdjusted = forced || !!router.options.history.state.back;
    if (!shouldBeAdjusted) return; // if we have no router history, we assume that user came from a link and doesn't want to adjust the map view
    if (!scenarioId) return store.dispatch('map/setPosition', store.state.scenarios.model); // without scenario - zoom to the model default coords
    await fetchScenario(scenarioId); // wait for the scenario in case it is not ready yet
    const modifications = getModifications(scenarioId, eventId, modificationId);
    if (modifications.length) _fitMapToItem(modifications, { layerSources: layerData.value, olObject, view });
  };

  const enterEditSessionIfNotInsideScenario = async () => {
    const activeScenarioId = parseRouteParamAsNumber(router.currentRoute?.value?.params?.id);
    const openedScenarioId = activeScenario.value.id;
    if (activeScenarioId != openedScenarioId) await enterEditSession(activeScenario.value);
  };

  const jumpToEvent = async (event: TmEvent, { jumpToDate = <TmDate>null } = {}) => {
    const scenarioId = activeScenario.value.id;
    await enterEditSessionIfNotInsideScenario();
    activateAndFitMapItem({ scenarioId, eventId: event.id, date: jumpToDate });
    router.push({
      name: 'scenarios.events.modifications',
      params: { id: scenarioId, evId: event.id },
    });
  };

  const jumpToModification = async (mod: TmModification, { jumpToDate = <TmDate>null } = {}) => {
    const scenarioId = activeScenario.value.id;
    const { type: modType, mode: modMode, eventId: evId, id: modId } = mod;
    await enterEditSessionIfNotInsideScenario();
    activateAndFitMapItem({ scenarioId, eventId: evId, modificationId: modId, date: jumpToDate });
    router.push({
      name: 'scenarios.events.modifications.edit',
      params: { id: scenarioId, evId, modType, modMode, modId },
    });
  };

  const openTimeline = () => {
    dialog.show({
      type: 'timeline',
      data: { events: activeScenario.value.events, modifications: activeScenario.value.modifications },
      callback: {
        onEventClick: (ev) => {
          jumpToEvent(ev, { jumpToDate: ev.dateFrom });
          dialog.close();
        },
        onModificationClick: (mod) => {
          jumpToModification(mod, { jumpToDate: mod.dateFrom });
          dialog.close();
        },
      },
    });
  };

  return {
    activateAndFitMapItem,
    adjustMapViewForMapItem,
    jumpToEvent,
    jumpToModification,
    fitMapToItem: (modifications: TmModification | TmModification[]) =>
      _fitMapToItem(modifications, { layerSources: layerData.value, olObject, view }),
    isTimelineActive: computed(() => !!activeScenario.value.id),
    openTimeline,
  };
}

// TS_TODO: map properties
async function _fitMapToItem(modifications: TmModification | TmModification[], { layerSources, olObject, view }: any) {
  const modArr = Array.isArray(modifications) ? modifications : [modifications];
  await loadLayerSourceGroup(_getLayerSourcesInMods(modArr, layerSources));

  const coordsArr = modArr.flatMap((mod) => _getModificationCoords(mod, layerSources));
  if (coordsArr.length === 0) return;
  const coordsExtent = boundingExtent(coordsArr);
  const mapSize = olObject.getSize();
  const widthPadding = Math.round(mapSize[0] * 0.2);
  const heightPadding = Math.round(mapSize[1] * 0.2);
  view.olObject.fit(coordsExtent, {
    maxZoom: 17,
    padding: [heightPadding, widthPadding, heightPadding, widthPadding],
    duration: 250,
    // TODO callback - might be used for mentioned post fit blink???
  });
}

// TS_TODO: layer property
function _getLayerSourcesInMods(mods: TmModification[], layerDataGroup: any) {
  // Modification of existing item might need baseModel data (not every time?)
  const typesInMods = mods.reduce((types, mod) => {
    const { type, mode } = mod;
    return mode === 'existing' ? types.add(type) : types;
  }, new Set());
  return Array.from(typesInMods).map((type: any) => layerDataGroup[type]);
}

// TS_TODO: layer property
function _getModificationCoords(modification: TmModification, layerSources: any) {
  const { mode, type } = modification;
  if (!type) throw new Error('Cannot get coordinations for modification with no type');

  if (mode === 'new') {
    const coordKey = newModCoordKey[type];
    const coords = modification[coordKey as keyof TmModification];
    return type === 'link' ? coords : [coords];
  }
  const { getFeatureData } = layerSources[type];
  const featureId = modification[featureKeyDict[type] as keyof TmModification];
  const featureIdArr = Array.isArray(featureId) ? featureId : [featureId];
  const coords = featureIdArr.flatMap((fId) => {
    const feature = getFeatureData(fId);
    if (!feature) return [];
    const isMulti = feature.geometry.type === 'MultiLineString';
    const fCoords = feature.geometry.coordinates;
    const flatCoords = isMulti ? fCoords.flat() : fCoords;
    return type === 'link' ? flatCoords : [flatCoords];
  });
  return coords;
}
