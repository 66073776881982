import { useStore } from 'vuex';

export enum TmStatus {
  Finished = 'finished',
  Computed = 'computed',
  Computing = 'computing',
  Initialized = 'initialized',
  Error = 'error',
  Unknown = 'unknown',
}

export type ItemWithStatus<T extends { status: string }> = T;

export default function useStatus() {
  const store = useStore();

  const getTmStatus = (item: ItemWithStatus<{ status: string }> | TmScenario, itemType: TmItemType): TmStatus => {
    const isScenario = itemType === 'scenarios';
    const isCalibration = itemType === 'calibrations';
    const isMatrix = itemType === 'matrices';

    if (isScenario) return getScenarioTmStatus(item as TmScenario);
    if (!('status' in item)) return TmStatus.Unknown;

    const hasStatus = (status: string) => item.status === status;

    const isFinished = (): boolean => {
      if (hasStatus('cached')) return true;
      if (hasStatus('calibrated') && isCalibration) return true;
      return false;
    };
    const isComputing = (): boolean => {
      if (hasStatus('calibrating')) return true;
      if (hasStatus('caching')) return true;
      return false;
    };
    const isInitialized = (): boolean => {
      if (hasStatus('calibrated') && isMatrix) return true;
      if (hasStatus('uncached')) return true;
      return false;
    };
    const isError = (): boolean => {
      if (hasStatus('uncalibrated')) return true;
      return false;
    };

    if (isFinished()) return TmStatus.Finished;
    if (isComputing()) return TmStatus.Computing;
    if (isInitialized()) return TmStatus.Initialized;
    if (isError()) return TmStatus.Error;

    return TmStatus.Unknown;
  };

  const getScenarioTmStatus = (scenario: TmScenario) => {
    const scenarioId = scenario.id;
    if (!scenarioId) return TmStatus.Unknown;

    const hasAccess = ['editor', 'inserter'].includes(scenario.level || '');
    if (!scenarioId || !hasAccess) return TmStatus.Unknown;

    const isBeingComputed = store.getters['scenarios/isScenarioBeingComputed'](scenarioId);
    const hasComputationReady = store.getters['scenarios/hasScenarioComputationReady'](scenarioId);
    const hasComputationError = store.getters['scenarios/hasScenarioComputationError'](scenarioId);

    if (hasComputationReady) return TmStatus.Computed;
    if (isBeingComputed) return TmStatus.Computing;
    if (hasComputationError) return TmStatus.Error;
    return TmStatus.Finished;
  };

  return { getTmStatus };
}

export const isCachedStatus = (status = '') => status === 'cached';
export const isCachingStatus = (status = '') => status === 'caching';
export const isUncachedStatus = (status = '') => status === 'uncached';
export const isCalibratedStatus = (status = '') => status === 'calibrated';
export const isCalibratingStatus = (status = '') => status === 'calibrating';
export const isUncalibratedStatus = (status = '') => status === 'uncalibrated';
