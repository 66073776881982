import MapLayout from '@layouts/Map.vue';
import store from '@store/index';
import { parseRouteParamAsNumber } from '@utils/route-param';
import type { RouteRecordRaw } from 'vue-router';

const ModelsView = () => import('@views/models/Models.vue');
const ModelRefreshView = () => import('@views/models/ModelRefresh.vue');
const ModelCalibrationsView = () => import('@views/models/ModelCalibrations.vue');
const ModelDatasetsView = () => import('@views/models/ModelDatasets.vue');
const CreateEditDatasetView = () => import('@views/models/CreateEditDataset.vue');
const ModelMatricesView = () => import('@views/models/ModelMatrices.vue');
const CreateEditMatrixView = () => import('@views/models/CreateEditMatrix.vue');
const CreateCalibrationView = () => import('@views/models/CreateCalibration.vue');
const ModelCalibrationView = () => import('@views/models/ModelCalibration.vue');
const CreateEditModelView = () => import('@views/models/CreateEditModel.vue');

const isAdminUser = () => store.getters['auth/hasRole']('admin');
const isModelManagerAndStaModel = () => {
  const isModelManager = store.getters['auth/hasRole']('modelManager');
  // this is not really bulletproof because in case of page refresh the model is not yet fetched before you try to access the route
  // but it should not matter much since there will be no matrix to calibrate the DTA model with..
  const isStaModel = !store.getters['scenarios/isDtaModelTypeActive'];
  return isModelManager && isStaModel;
};

const routes: RouteRecordRaw[] = [
  {
    path: '/models',
    name: 'models',
    component: ModelsView,
    meta: {
      layout: MapLayout,
      requiresAuth: false,
    },
  },
  {
    path: '/models/new',
    name: 'models.create',
    component: CreateEditModelView,
    meta: {
      layout: MapLayout,
      requiresAuth: true,
    },
    beforeEnter: isAdminUser,
  },
  {
    path: '/models/:modelId',
    name: 'models.edit',
    component: CreateEditModelView,
    meta: {
      layout: MapLayout,
      requiresAuth: true,
    },
    props: ({ params }) => ({
      modelId: parseRouteParamAsNumber(params.modelId),
    }),
    beforeEnter: isAdminUser,
  },
  {
    path: '/models/refresh',
    name: 'models.refresh',
    component: ModelRefreshView,
    meta: {
      layout: MapLayout,
      requiresAuth: true,
    },
    beforeEnter: () => store.getters['auth/hasRole']('modelManager'),
  },
  {
    path: '/models/calibrations',
    name: 'models.calibrations',
    component: ModelCalibrationsView,
    meta: {
      layout: MapLayout,
      requiresAuth: true,
    },
    beforeEnter: isModelManagerAndStaModel,
  },
  {
    path: '/models/datasets',
    name: 'models.datasets',
    component: ModelDatasetsView,
    meta: {
      layout: MapLayout,
      requiresAuth: true,
    },
    beforeEnter: isModelManagerAndStaModel,
  },
  {
    path: '/models/matrices',
    name: 'models.matrices',
    component: ModelMatricesView,
    meta: {
      layout: MapLayout,
      requiresAuth: true,
    },
    beforeEnter: isModelManagerAndStaModel,
  },
  {
    path: '/models/matrices/new',
    name: 'models.matrices.create',
    component: CreateEditMatrixView,
    meta: {
      layout: MapLayout,
      requiresAuth: true,
    },
    beforeEnter: isAdminUser,
  },
  {
    path: '/models/matrices/:matrixId',
    name: 'models.matrices.edit',
    component: CreateEditMatrixView,
    meta: {
      layout: MapLayout,
      requiresAuth: true,
    },
    props: ({ params }) => ({
      matrixId: parseRouteParamAsNumber(params.matrixId),
    }),
    beforeEnter: isModelManagerAndStaModel,
  },
  {
    path: '/models/datasets/new',
    name: 'models.datasets.create',
    component: CreateEditDatasetView,
    meta: {
      layout: MapLayout,
      requiresAuth: true,
    },
    beforeEnter: isModelManagerAndStaModel,
  },
  {
    path: '/models/datasets/:datasetId',
    name: 'models.datasets.edit',
    component: CreateEditDatasetView,
    meta: {
      layout: MapLayout,
      requiresAuth: true,
    },
    props: ({ params }) => ({
      datasetId: parseRouteParamAsNumber(params.datasetId),
    }),
    beforeEnter: isModelManagerAndStaModel,
  },
  {
    path: '/models/calibrations/new',
    name: 'models.calibrations.create',
    component: CreateCalibrationView,
    meta: {
      layout: MapLayout,
      requiresAuth: true,
    },
    beforeEnter: isModelManagerAndStaModel,
  },
  {
    path: '/models/calibrations/:calibrationId/new',
    name: 'models.calibrations.precreate',
    component: CreateCalibrationView,
    meta: {
      layout: MapLayout,
      requiresAuth: true,
    },
    props: ({ params }) => ({
      calibrationId: parseRouteParamAsNumber(params.calibrationId),
    }),
    beforeEnter: isModelManagerAndStaModel,
  },
  {
    path: '/models/calibrations/:calibrationId',
    name: 'models.calibrations.calibration',
    component: ModelCalibrationView,
    meta: {
      layout: MapLayout,
      requiresAuth: true,
    },
    props: ({ params }) => ({
      calibrationId: parseRouteParamAsNumber(params.calibrationId),
    }),
    beforeEnter: isModelManagerAndStaModel,
  },
];

export default routes;
